@import "vars.scss";
@import "fonts.scss";

.popup-opened {
    overflow: hidden;
}

.ajax-progress-throbber {
    display: none!important;
}
.tel-code {
    position: relative;
    color: $footer_text_color;
}
.main-wrapper {
    position: relative;
        overflow: hidden;
    min-height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 320px;
    @media (max-width: $md - 1px) {
        padding-left: 0;
        padding-right: 0;
    }
    @media (max-width: $sm - 1px) {
        padding-left: 0;
        padding-right: 0;
    }

    &.bg-title {
        .midlle-wrapper {
            .container {
                padding-left: 20px;
                padding-right: 20px;

                @media (min-width: $lg) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
}

.front {
    .content-wrapper {
        @media (max-width: $sm - 1px) {
            padding-bottom: 0;
        }
    }
}
.content-wrapper {
    padding-bottom: 46px;
    padding-top: 100px;
    @media (max-width: $lg - 1px) {
        padding-bottom: 70px;
        padding-top: 80px;
    }
    @media (max-width: $md - 1px) {
        padding-bottom: 170px;
        padding-top: 62px;
    }
    @media (max-width: $sm - 1px) {
        padding-bottom: 240px;
    }
    .header-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background: #fff;
        z-index: 200;
        box-shadow: 0 -12px 15px 10px #000;
        .container {
            width: auto;
            /* @media (max-width: $lg - 1px) {
                padding-left: 10px;
                padding-right: 10px;
            }

            @media (min-width: $md) {
                padding-left: 30px;
                padding-right: 10px;
                width: auto;
                max-width: 1350px;
            } */
            @media (min-width: $lg) {
                padding-left: 20px;
                padding-right: 20px;
                width: auto;
                max-width: 1350px;
            }
        }
    }
    // .midlle-wrapper {

    //     @media (max-width: $lg - 1px) {

    //     }
    //     @media (max-width: $md - 1px) {

    //     }
    // }
}
.container {

    @media (max-width: $sm - 1px) {
        // width: 300px;
        padding-left: 20px;
        padding-right: 20px;
    }
     @media (max-width: $md - 1px) and (min-width: $sm) {
        width: auto;
        padding: 0 30px !important;
    }
}

#header {
    .header-row {
        margin-top: 15px;
        @media (max-width: $md - 1px) {
            margin-top: 10px;
        }
        @media (max-width: $sm - 1px) {
            p {
                margin-bottom: 0;
            }
            margin-top: 10px;
            margin-left: 0;
            .col-xs-2 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .header-row .header-col .block {
        display: inline-block;
        position: relative;
    }
    .header-col {
        @media (max-width: $md - 1px) {
            //text-align: center;
        }
    }
    .logo {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        width:80px;
        height:70px;
        margin-bottom: 15px;
        @media (max-width: $lg - 1px) {
            width: 59px;
            height: 50px;
        }
        @media (max-width: $md - 1px) {
            width: 48px;
            height: 42px;
            margin-left: -3px;
            margin-bottom: 10px;
        }
        @media (max-width: $sm - 1px) {
            margin-bottom: 10px;
        }
        &:hover {
            .logo-link {
                &:before {
                    opacity: 0.7;
                }
            }
        }
        .logo-link {
            img {
                width:100%;
                height:auto;
            }
        }
    }
    .header-col-right {
        font-size: 1.42em;
        text-align: right;
        padding-top: 15px;
        white-space: nowrap;
        @media (max-width: $lg - 1px) {
            padding: 10px 0 0;
            font-size: 1.14em;
        }
        @media (min-width: $md) {
            width: 50%;
        }
        .city-changer, .email-block, .phone-block, .callback-block, .menu-initiator {
            display: inline-block;
            padding: 0 0 0 10px;
            margin-left: 15px;
        }
        .city-changer {
            /* display: block;
            float: left;
            position: relative;
            margin-left: 118px;*/
            vertical-align: top;
            height: 30px;
            cursor: pointer;
            margin-top: -5px;
            .city-option {
                cursor: pointer;
            }

            .selected-city {
                /* position: absolute;
                top: 0px;
                left: 10px; */
                padding: 6px 10px;
                &:hover,
                &:active {
                    color: $line_active_color;
                }
            }
            .unselected-city {
                color: $form_hover_color;
                position: absolute;
                top: 34px;
                left: 10px;
                display: none;
                padding: 6px 10px;
                &:hover,
                &:active {
                    color: $line_active_color;
                }

                a {
                  &:hover,
                  &:active {
                    color: #E8771A;
                    border-color: #E8771A;
                  }
                }
            }
            &:hover,
            &.opened {
                .selected-city {
                    background-color: #ebebeb;
                }
                .unselected-city {
                    display: block;
                    background-color: #ebebeb;

                }
            }
            .border-dotted {
                border-bottom:1px dotted;
            }
            @media (max-width: $lg - 1px) {
                &:hover,
                &.opened {
                }
                .unselected-city {
                    top: 30px;
                    left: 10px;
                }
            }
            @media (max-width: $md - 1px) {
                margin: -3px 10px 0 0;

                &:hover,
                &.opened {
                    height: 40px;
                }
                .unselected-city {
                    top: 27px;
                    padding: 3px 6px;
                }
                .selected-city {
                    padding: 3px 6px;
                }
            }
            @media (max-width: $sm - 1px) {
                vertical-align: top;
                margin: -3px 10px 0 0;
                &:hover,
                &.opened {
                }
                .unselected-city {
                    top: 27px;
                    padding: 3px 6px;


                }

            }
        }
        .email-block {
            @media (max-width: $md - 1px) {
                padding: 0;
            }
        }
        .phone-block {
            width: 112px;
            padding: 0;
            margin-left: 30px;
            .phone-block-wrapper {
                text-align: left;
            }
            @media (max-width: $lg - 1px) {
                width: 90px;
                padding: 0;
            }
            @media (max-width: $md - 1px) {
                width: 95px;
                padding: 0;
            }
            @media (max-width: $sm - 1px) {
                margin-left: 0;
                width: 83px;
                padding: 0;
            }
        }
        .callback-block {
            position: relative;
            width: 20px;
            height: 22px;
            margin-top: 0;
            padding: 0;
            vertical-align: middle;
            .callback-link {
                position: absolute;
                display: block;
                width: 20px;
                height: 22px;
                background: url(../images/telefon-icon.png) 0 0 no-repeat;
                top: 0;
                left: 0;
                text-decoration: none;
            }
            &:hover {
                .callback-link {
                    background-position: 0 -22px;
                }
            }
            .callback-link {
                @media (max-width: $lg - 1px) {
                    margin-top: 0;
                }
                @media (max-width: $md - 1px) {
                    margin-top: 0;
                }
            }
        }
        .menu-initiator {
            position: relative;
            width: 31px;
            height: 22px;
            margin-top: 2px;
            margin-left: 30px;
            // margin-right: 30px;
            padding: 0;
            vertical-align: top;
            @media (max-width: $lg - 1px) {
                margin-top: 0;
                margin-right: 15px;
            }
            @media (max-width: $md - 1px) {
                margin-top: 0;
                margin-right: 10px;
            }
            @media (max-width: $sm - 1px) {
                margin-top: 0;
                margin-left: 15px;
                margin-right: 10px;
            }
            .menu-link {
                position: absolute;
                display: block;
                width: 31px;
                height: 22px;
                background: url(../images/menu-icon-4.png) 0 0 no-repeat;
                top: 0;
                left: 0;
                cursor: pointer;
            }
            .menu-background {
                position: absolute;
                display: block;
                width: 31px;
                height: 22px;
                background: url(../images/menu-icon-3.png) 0 0 no-repeat;
                top: 0;
                left: 0;
                cursor: pointer;
            }
        }
    }
    .all-tabs-vector {
        display: inline-block;
        position: relative;
        vertical-align: text-bottom;
        .vector-uslugi {
            font-size:1.42em;
            line-height: 1;
            display: inline-block;
            padding: 0 0 10px 10px;
            margin-left: 40px;
            border-bottom: 2px solid $line_color;
            border-left: 2px solid $line_color;
            &:hover {
                text-decoration: none;
                border-color: $line_hover_color;
            }
            &:active {
                border-color: $line_active_color;
            }
            @media (max-width: $lg - 1px) {
                font-size:1.14em;
                padding: 0 0 5px 8px;
            }
            @media (min-width:$sm) and (max-width: $lg) {
                margin-left:20px;
            }
            @media (min-width: $md) {
                font-size: 16px;
                letter-spacing: 0.25px;
            }

            @media (min-width: $lg) {
                font-size: 20px;
                letter-spacing: 0.25px;
            }
        }
    }
    .region-header {
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 420px;
        height: 70px;
        color: $white_text;
        opacity: 0.9;
        a {
            color: $white_text;
            text-decoration: none;
        }
        .block-block {
            display: inline-block;
            padding: 0 0 10px 10px;
            margin-left: 20px;
            font-family: 'BebasNeue';
            font-size: 20px;
        }
    }
}

.pseudo-header {
    z-index: 20;
    .city, .email-block, .phone-block, .callback-block, .menu-initiator {
        display: inline-block;
        vertical-align: top;
        letter-spacing: 0.2px;
    }
    .city-changer {
        position: relative;
        height: 30px !important;
        /* overflow: hidden; */
        cursor: pointer;
        .city-item {
            cursor: pointer;
            padding: 2px 6px 4px;
            margin-top: -2px;
            &:hover {

            }

            span,
            a {

            }

            .selected-city {
                color: #fff;
                background: -moz-linear-gradient(left, #e2e2e2, #e2e2e2 50%, transparent 50%);
                background: -ms-linear-gradient(left, #e2e2e2, #e2e2e2 50%, transparent 50%);
                background: -o-linear-gradient(left, #e2e2e2, #e2e2e2 50%, transparent 50%);
                background: -webkit-gradient(linear, 0 0, 100% 0, from(#e2e2e2), color-stop(0.5, #e2e2e2), color-stop(0.5, transparent));
                background: -webkit-linear-gradient(left, #e2e2e2, #e2e2e2 50%, transparent 50%);
                background: linear-gradient(left, #e2e2e2, #e2e2e2 50%, transparent 50%);
                background-size: 3px 1px;
                background-repeat: repeat-x;
                background-position: 0 100%;

                &:hover,
                &:active {
                    color: $line_active_color;
                    background: -moz-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                    background: -ms-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                    background: -o-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                    background: -webkit-gradient(linear, 0 0, 100% 0, from($line_active_color), color-stop(0.5, $line_active_color), color-stop(0.5, transparent));
                    background: -webkit-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                    background: linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                    background-size: 3px 1px;
                    background-repeat: repeat-x;
                    background-position: 0 100%;
                }
            }

            &.unselected-city {
                position: absolute;
                top: 30px;
                left: 0;
                a {
                    text-decoration: none;
                    // border-bottom:1px dotted;
                    background: -moz-linear-gradient(left, #ccc, #ccc 50%, transparent 50%);
                    background: -ms-linear-gradient(left, #ccc, #ccc 50%, transparent 50%);
                    background: -o-linear-gradient(left, #ccc, #ccc 50%, transparent 50%);
                    background: -webkit-gradient(linear, 0 0, 100% 0, from(#ccc), color-stop(0.5, #ccc), color-stop(0.5, transparent));
                    background: -webkit-linear-gradient(left, #ccc, #ccc 50%, transparent 50%);
                    background: linear-gradient(left, #ccc, #ccc 50%, transparent 50%);
                    background-size: 3px 1px;
                    background-repeat: repeat-x;
                    background-position: 0 100%;
                    color: #ccc;

                    &:hover,
                    &:active {
                        color: $line_active_color;
                        background: -moz-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                        background: -ms-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                        background: -o-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                        background: -webkit-gradient(linear, 0 0, 100% 0, from($line_active_color), color-stop(0.5, $line_active_color), color-stop(0.5, transparent));
                        background: -webkit-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                        background: linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                        background-size: 3px 1px;
                        background-repeat: repeat-x;
                        background-position: 0 100%;
                    }
                }
            }

        }

        &:hover,
        &.clicked {
            .unselected-city {
                display: block;
            }

            .city-item {
                background: rgba(37, 37, 37, 0.9);

                span, a {
                    color: rgb(168,168,168);
                }
            }
        }

        .unselected-city {
            display: none;
        }

        .selected-city {
            span {
               text-decoration: none;
                display: inline;
                background: -moz-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                background: -ms-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                background: -o-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                background: -webkit-gradient(linear, 0 0, 100% 0, from($line_active_color), color-stop(0.5, $line_active_color), color-stop(0.5, transparent));
                background: -webkit-linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                background: linear-gradient(left, $line_active_color, $line_active_color 50%, transparent 50%);
                background-size: 2px 1px;
                background-repeat: repeat-x;
                background-position: 0 100%;
            }

            &:hover {
                color: $line_active_color;
            }
        }

        .border-dotted {
            border-bottom:1px dotted;
        }
        @media (max-width: $lg - 1px) {
            padding-left: 0;
            height: 22px;
            &:hover {
                height: 53px;
            }
            .unselected-city {
                top: 25px;
            }
        }
        @media (max-width: $md - 1px) {
            &:hover {
                height: 40px;
            }
            .unselected-city {
                top: 20px;
            }
        }
        @media (max-width: $sm - 1px) {
            line-height: 1;
            height: 20px;
            &:hover {
                height: 40px;
            }
            .unselected-city {
                top: 20px;
                left: 5px;
            }
            .selected-city {
                top: 2px;
                left: 5px;
            }
        }
    }
    .email-block {
        padding: 0 14px;

        a,
        a:visited {
            color: #fff;
            text-decoration: none;

            &:hover,
            &:active {
                color: #E8771A;
            }
        }
    }
    .phone-block {
        text-align: left;
        color: $white_text;
        a .tel-code {
            color: #a8a8a8;
            line-height: 1.0;
        }
        a span {
            color: $white_text;
            line-height: 1.0;
        }
    }
    .callback-block {
        position: relative;
        width: 20px;
        height: 22px;
        padding: 0;
        vertical-align: middle;
        margin-left: 12px;
        .callback-link {
            position: absolute;
            display: block;
            width: 20px;
            height: 22px;
            background: url(../images/telefon-icon.png) 0 0 no-repeat;
            top: 0;
            left: 0;
            text-decoration: none;
        }
        &:hover {
            .callback-link {
                background-position: 0 -22px;
            }
        }
        .callback-link {
            @media (max-width: $lg - 1px) {
                margin-top: 0;
            }
            @media (max-width: $md - 1px) {
                margin-top: 0;
            }
        }
    }
    .menu-initiator {
        position: absolute;
        width: 80px;
        height: 80px;
        right: 0;
        top: 0;
        background: url(../images/menu-icon-3.png) 50% 50% no-repeat rgba(255,255,255,0.5);
        .menu-link {
            position: absolute;
            width: 80px;
            height: 80px;

            top: 0;
            left: 0;
            cursor: pointer;


        }
/*         .menu-background {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    background: url(../images/menu-icon-3.png) 50% 50% no-repeat rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    cursor: pointer;
}
 */
        &:hover {
            background: url(../images/menu-icon-4.png) 50% 50% no-repeat rgba(255,255,255,1);
        }

        @media (min-width: $lg) {
            width: 120px;
            height: 100px;
        }
    }
}
.contacts-block {
    margin-left: 116px;
    width: 320px;
    @media (max-width: $lg - 1px) {
        margin-left: 73px;
    }
    @media (max-width: $md - 1px) {
        margin-left: 0;
    }
    .contact-item {
        margin-top: 20px;
        line-height: 1.0;
        color: #fff;

        .phone-block-wrapper {
            margin-top: 7px;
            a {
                font-size: 36px;
                color: #fff;

                @media (min-width: $lg) {
                    font-size: 44px;
                    line-height: 44px;
                }
            }
        }
        .mail-text {
            color: $white_text;
            font-size: 22px;

            @media (min-width: $md) {
                font-size: 36px;
                line-height: 40px;
            }

            @media (min-width: $lg) {
                font-size: 40px;
                line-height: 44px;
            }
        }
        &:first-child {
            margin-top: 0;
        }

        .adres-wrapper {
            color: rgb(211,211,211);

            @media (min-width: $lg) {
                margin: 30px 0 0 0;
            }

        }

        @media (max-width: $lg - 1px) {
            margin-top: 23px;
        }
        @media (max-width: $md - 1px) {
            margin-top: 24px;
        }
    }
    .contact-item-small {
        margin-top: 15px;
        margin-left: 0;
        .menu-social-icons {
            position: static;
            margin-left: -15px;
        }
        @media (max-width: $lg - 1px) {
            margin-top: 15px;
            .menu-social-icons {
                position: static;
                margin-left: -15px;
            }
        }
        @media (max-width: $md - 1px) {
            margin-top: 30px;
            .menu-social-icons {
                position: static;
            }
        }
        @media (max-width: $sm - 1px) {
            margin-top: 30px;
            .menu-social-icons {
                margin-top: 0px;
            }
        }
    }
    .menu-city-changer {
        display: inline-block;
        width: 268px;
        border: 1px solid $gray_fon;
        .city-option {
            display: block;
            cursor: pointer;
            float: left;
            width: 50%;
            text-align: center;
            line-height: 48px;
        }
        .selected-city {
            color: $line_active_color;
            background-color: $select_city_color;
        }
        .unselected-city {
            color: $button_close_color;
            background-color: $gray_fon;
            &:hover {
                color: $white_text;
            }
        }
    }
    .menu-phone-block {
        font-size: 3.13em;
        color: $white_text;
        a .tel-code {
            color: $form_text_color;
            line-height: 1.0;
        }
        span {
            color: $white_text;
            line-height: 1.0;
        }
        @media (max-width: $lg - 1px) {
            font-size: 2.56em;
        }
    }
    .menu-callback {
        display: inline-block;
        .callback-items {
            vertical-align: top;
            display: inline-block;
            .callback-challenge {
                display: block;
                float: left;
                height: 22px;
                width: 22px;
                background: url(../images/menu-telefon-icon.png) 0 0 no-repeat;
                text-decoration: none;
            }
            .callback-text {
                display: inline-block;
                margin-left: 5px;
                border-bottom: 1px dotted;
                color: $line_active_color;
                line-height: 1.3;
            }
            &:hover {
                .callback-challenge {
                    background-position: 0 -22px;
                }
                .callback-text {
                    color: $white_text;
                }
            }
        }
    }
    .menu-email {
        font-size: 2.85em;
        line-height: 1.0;
        .mail-text {
            color: $white_text;
            font-size: 22px;
        }
        @media (max-width: $lg - 1px) {
            font-size: 2.56em;
        }
    }
    .short-adres {
        color: $button_close_color;
    }
    .question {
        text-align: center;
        .menu-button {
            display: block;
            color: $white_text;
            border: 1px solid #cccccc;
            width: 268px;
            height: 60px;
            line-height: 58px;
            &:hover {
                border-color: $line_active_color;
            }
        }
    }
    .menu-social-icons {
        margin-left: -25px;
    }
}

#header.default {
    background: transparent;
    box-shadow: none;

    #block-custom-service-menu {
        display: none;
    }

    .header-col-right {
        color: #fff;
        margin-right: 100px;
        width: 310px;
        padding: 25px 20px;
        background: rgba(0, 0, 0, 0.5);
    }

    .logo {
        img {
            display: none;
        }

        width: 100px;
        height: 80px;
        padding: 10px 20px;

        margin: -15px 0 0 -30px;
        background:  rgba(255, 255, 255, 0.5);

        a {
            display: block;
            height: 50px;
            width: 60px;
            text-decoration: none;
            background: url(../images/logo-5.png) no-repeat 50% 50%;
            background-size: contain;
        }


    }

    #block-block-9 {
        width: 80px;
        height: 80px;
        position: absolute;
        right: -12px;
        top: -15px;
        background: rgba(255, 255, 255, 0.5);
        margin: 0;
        .menu-link {
            width: 80px;
            height: 80px;
        }

        .menu-background {
            width: 80px;
            height: 80px;
            background-position: 50% 50%;
        }
    }
}

.content {
    .field-name-field-sub-category {
        display: none;
    }
    .content-block-header {
        padding-top: 30px;
        @media (max-width: $md - 1px) {
            padding-top: 38px;
        }
    }
}
.border-left {
    padding-left: 12px;
    border-left: 2px solid $line_active_color;
}
.sec_buttons {
  div {
    display: inline-block;
  }
}
.main-button {
    display: block;
    margin-top: 30px;
    width: 200px;
    height: 50px;
    line-height: 48px;
    color: $white_text;
    border: 1px solid rgba(255,255,255,0.6);
    text-align: center;
    position: relative;
    &:hover {
        color: $white_text;
    }
    @media (max-width: $md - 1px) {
        width: 280px;
        height: 60px;
        font-size: 16px;
        line-height: 58px;
    }

    &.sertificat-button {
      width: 340px;
      margin-left: 20px;
      color: #E8771A;

      @media (max-width: $md - 1px) {
        width: 440px;
        margin-left: 0px;
      }

      @media (max-width: $sm - 1px) {
        width: 280px;
        padding-left:0;

        &:before {background:none;}
      }
    }
}
.top-button {
    &:after {
        display: block;
        position: absolute;
        content: " ";
        width: 198px;
        height: 48px;
        top: 0;
        left: 0;
    }
    &:hover {
        color: $white_text;
        &:after {
            background-color: #fff;
            opacity: 0.2;
        }
    }
    @media (max-width: $md - 1px) {
        &:after {
            width: 278px;
            height: 58px;
        }
    }
}
.customers-button {
    @media (max-width: $md - 1px) {
        margin-top: 50px;
    }
}
.license-button {
    padding-left: 25px;
    color: $line_active_color;
    border: 1px solid $line_hover_color;
    &:hover {
        color: $line_active_color;
    }
    &:before {
        content: " ";
        display: block;
        position: absolute;
        width: 16px;
        height: 22px;
        top: 12px;
        left: 50px;
        background: url(../images/license-icon.png) 0 0 no-repeat;
    }
}
.orange-hover-4-button {
    &:hover {
        border-color: $line_active_color;
    }
}
.bottom-line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    opacity: 0.5;
    margin-top: 5px;
    @media (max-width: $md - 1px) {
        width: 280px;
    }
}
.top-block {
    position: relative;
    /* min-height: 280px; */
    color: $white_text;
    background: url(../images/antenna-1.png) 0px -160px no-repeat $main_text;
    padding: 50px 40px;
    //background-size: cover;
    background-size: 100% auto;
    background-position: 50% 0%;
    &:before {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $main_text;
        opacity: 0.3;
        margin-top:-50px;
        margin-left:-40px;
    }
    @media (min-width: $md) {
        min-height: auto;
        margin: 0 -15px;
        padding: 50px 34px;
    }
    @media (max-width: $md - 1px) {
        padding-left: 30px;
        &:before {
            margin-left:-30px;
        }
    }
    @media (max-width: $sm - 1px) {
        padding: 50px 0px;
        // width: 300px;
        &:before {
            margin-left:0;
        }
        background-position: 50% 0%;
        background-size: cover;
    }
    .top-block-wrapper {
        position: relative;
        margin: 0 auto;
        h1 {
            margin-top:0;
            line-height: 0.8em;
        }
        // .wrapper-II.container {
        //     padding: 0 !important;
        // }
        max-width: 1350px;
        padding: 0 20px;
        .main-button, .main-button:visited, .main-button:focus {
            color: #fff;
        }
        @media (max-width: $md - 1px) {
           /*  width: 610px; */
            margin: 0 auto;
            padding: 0;
            .border-left {
                h1 {
                    font-size: 2.14em;
                    line-height: 0.8em;
                }
            }
        }
        @media (max-width: $sm - 1px) {
            margin-left: 0;
            padding: 0 20px;
            .border-left {
                border-left: 0;
                padding: 0;
                h1 {
                    font-size: 1.57em;
                    line-height: 1.1em;
                }
            }
        }
        @media (min-width: $md) {
            padding: 0;
        }
        @media (min-width: $lg) {
            padding: 0 40px;
        }
        .top-block-menu {
            margin-top: 30px;

            h3 {
                margin-bottom: 0;
            }
        }
        .list-wrapper {
           /*  max-width: 852px; */
            margin-top: 20px;
            line-height: 0em;
            .item-list {
                display: inline-block;
                padding: 0 15px;
                height: 40px;
                line-height: 40px;
                margin: 0 3px 3px 0;
                background-color: $gray_fon;
                text-align: center;
                color: $white_text;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
/*             @media (max-width: $md - 1px) {
    max-width: 544px;
}
@media (max-width: $sm - 1px) {
    max-width: 100%;
} */
        }
        @media (max-width: $sm - 1px) {
            // width: 100%;
           /*  width: 320px; */
            margin: 0 auto;
            h3 {
                font-size: 1.4em;
            }
        }
    }
}

.content-wrapper {

}

#block-views-team-block {
    display: inline-block;
    max-width: 900px;
    padding-top: 50px;
    .block-title {
        margin-bottom: 0;
        margin-top: 0;
    }
    .view-team {
        display: inline-block;
        margin: 30px 0;
        text-align: left;
        h3 {
            display: block;
            font-size: 1.71em;
            line-height: 1.0;
            clear: both;
            margin-top: 0;

            @media (min-width: $sm) {
                margin-bottom: 30px;
            }
        }
        .views-row {
            display: inline-block;
            float: left;
            width: 200px;
            text-align: center;
            margin-right: 20px;
            margin-bottom: 40px;
            .views-field-field-photo {
                display: inline-block;
                width: 190px;
                height: 220px;
                // overflow: hidden;
                text-align: center;
                background: #e9e9e9;

            }
            .views-field-field-photo img {
                height: 100%;
            }
            .views-field-title {
                display: block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                margin-top: -5px;
                padding-left: 10px;
                text-align: left;
                font-size: 1.28em;
                color: $white_text;
                background-color: $line_active_color;
                font-family: $bebas_font;
                z-index: 2;
                position: relative;
            }
            .views-field-field-position {
                display: block;
                /* height: 100px; */
                padding-top: 10px;
                padding-left: 10px;
                text-align: left;
                font-size: 0.85em;
                color: $main_text;
            }
        }
    }

    @media (min-width: $md) {
        padding-left: 20px;
    }

    @media (min-width: $lg) {
        padding-left: 15px;
    }
}

#block-block-28 {
    @media (min-width: $md) {
        padding-left: 20px;
    }

    @media (min-width: $lg) {
        padding-left: 0;
        width: 730px;
    }
}
#breadcrumb {
    @media (min-width: $lg) {
        padding-left: 0;
        width: 730px;
    }
}

// 404
.page-node-18 {
   background-color: #252525;
   .content-wrapper {
        padding-top: 100px;

        @media (min-width: $lg) {
          position: absolute;
          width: 100%;
          bottom: 50px;
        }
   }
}
.not-found-wrapper {
    background-color: $main_text;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    .not-found {
        padding-top: 60px;
        padding-left: 30px;
        margin-bottom: -5px;
        .number-wrapper {
            display: inline-block;
            position: relative;
            height: 565px;
            width: 634px;
            background: url(../images/0-1.png) 17px 110px no-repeat;
            &:before {
                content: " ";
                display: block;
                position: absolute;
                top: 132px;
                left: 26px;
                height: 431px;
                width: 139px;
                background: url(../images/44-9.png) 0px 0px no-repeat;
            }
            .number-page {
                display: block;
                font-size: 12.86em;
                line-height: 0.72;
                color: $white_text;
            }
            .contour {
                display: inline-block;
                position: relative;
                margin-left: 270px;
                height: 431px;
                width: 367px;
                background: url(../images/0-2.png) 0px 4px no-repeat;
                &:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    top: 36px;
                    left: 222px;
                    height: 400px;
                    width: 76px;
                    background: url(../images/44-8.png) 0px 0px no-repeat;
                }
                @media (max-width: $md - 1px) {
                    margin-left: 240px;
                }
            }
        }
        .messages-block {
            display: inline-block;
            height: 565px;
            width: 610px;
            padding-top: 50px;
            background: url(../images/city_light.png) -1112px 225px no-repeat;
            background-size: auto 60%;
            overflow: hidden;
            @media (max-width: $md - 1px) {
                height: 0;
                padding-top: 0;
            }
            .messages-wrapper {
                .message-page {
                    display: block;
                    padding-left: 18px;
                    padding-bottom: 20px;
                    font-size: 1.57em;
                    line-height: 1;
                    color: $white_text;
                }
            }
            .link-start-page {
                display: block;
                color: $gray_message;
                font-size: 1.43em;
                line-height: 1;
                padding: 0 0 10px 10px;
                margin-left: 6px;
                border-bottom: 2px solid $gray_fon;
                border-left: 2px solid $gray_fon;
                width: 150px;
                &:hover {
                    color: $white_text;
                    border-color: $white_text;
                }
                &:active {
                    color: $white_text;
                    border-color: $line_active_color;
                }
            }
        }
    }
    @media (max-width: $lg - 1px) {
        .not-found {
            padding-left: 15px;
            .messages-block {
                width: 330px;
            }
        }
    }
    @media (max-width: $md - 1px) {
        margin-left: 0;
        margin-right: 0;
        .not-found {
        margin-bottom: -25px;
            .messages-block {
                .messages-wrapper {
                    position: absolute;
                    top: 100px;
                    left: 350px;
                }
            }
        }
    }
    @media (max-width: $sm - 1px) {
        .not-found {
            padding-top: 30px;
            padding-left: 10px;
            .number-wrapper {
                height: 540px;
                width: 255px;
                background-position: 0 202px;
                &:before {
                    top: 202px;
                    background-size: 78%;
                }
                .number-page {
                    font-size: 6.86em;
                }
                .contour {
                    display: none;
                }
            }
            .messages-block {
                .messages-wrapper {
                    position: absolute;
                    padding-top: 30px;
                    padding-left: 12px;
                    border-left: 2px solid #E8771A;
                    top: 99px;
                    left: 25px;
                    .message-page {
                        padding-left: 0px;
                        font-size: 1.28em;
                    }
                    .link-start-page{
                        font-size: 1.28em;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.title-block {
    position: relative;
    height: 240px;
    padding-left: 0;
    padding-right: 0;
    color: #fff;
    background: url(../images/city_light.png) -85px 20px no-repeat #252525;
    overflow: hidden;
    background-size: auto 90%;

    .top-block-wrapper {
        height: 100%;
        margin-left: 40px;
        .items-text-wrapper {
            margin-top: 30px;
            .section-text {
                display: inline-block;
                    p {
                        margin-bottom: 5px;
                    }
                .sub-title, .digits {
                    font-size: 1.57em;

                    @media (min-width: 22px) {
                        font-size: 22px;
                    }
                }
            }
            .divide {
                display: inline-block;
                width: 90px;
                margin: 0 30px;
                margin-bottom: 37px;
                border-top: 2px solid $line_hover_color;
            }
        }

        @media (max-width: $lg - 1px) {
            width: 1024px;
        }
        @media (max-width: $md - 1px) {
            width: 640px;
        }
        @media (max-width: $sm - 1px) {
            width: 320px;
        }
    }

    @media (max-width: $md - 1px) and (min-width: 768px) {
        .top-block-wrapper {
            width: 740px;
            margin: 0 auto;
        }
    }

    @media (max-width: $lg - 1px) and (min-width: $md) {
        margin: 0 -15px;
        .block-title h1 {
            font-size: 2.14em;
            margin-top: 0;
            line-height: 1em;
        }

        .top-block-wrapper {
            margin: 0 auto;
            width: 980px;
            height: auto;
        }

        .title-block__container {
            padding-top: 50px;
        }

        &.simple {
            .top-block-wrapper {
                margin: 0 auto;
                width: 980px;
                height: auto;
                
            }


        } 
    }


    @media (min-width: $md) {
        .top-block-wrapper,
        .title-block__container {
            height: 100%;
        }

        &.simple {
            .top-block-wrapper {
                padding: 0 30px;
                width: auto;
                max-width: 1305px;

                #breadcrumb {
                    .breadcrumb {

                    }
                }
            }
        }
    }
    @media (min-width: $lg) {
        margin: 0 -20px;
        .top-block-wrapper {
            width: 1280px;
            margin: 0 auto;
        }

        .title-block__container {
            padding-top: 50px;
        }

        .block-title h1 {
            font-size: 30px;
            margin-top: 0;
            line-height: 1em;
        }
    }

    @media (max-width: $md - 1px) {
        height: 500px;
        background-position: -288px 250px;
        background-size: auto 50%;
        .block-title h1 {
            font-size: 2.14em;
        }
        .border-left {
            padding-left: 0;
            border-left: 0;
        }

        .top-block-wrapper {
           width: auto;
            margin: 0 auto;
            padding: 40px 50px;
            .items-text-wrapper {
                margin-top: 40px;
                .section-text {
                    display: block;
                    .item-text {
                        font-size: 1.14em;
                    }
                }
                .divide {
                    display: block;
                    border: none;
                    border-left: 2px solid $line_hover_color;
                    height: 50px;
                    margin: 10px 0;
                    margin-left: 35px;
                }
            }
        }
        &.simple {
            min-height: 180px;
            height: auto;
            background-position: -220px 100%;
            background-size: auto 100%;
            .top-block-wrapper {
                padding: 30px 20px;

                .breadcrumb {
                    padding: 0 0 24px;
                    color: rgb(211,211,211);
                    font-size: 11px;
                    line-height: 14px;
                    a {
                        color: rgb(211,211,211);
                    }
                }

                @media (min-width: $sm) {
                    padding: 30px 50px;
                }
            }
        }
    }

    @media (max-width: $sm - 1px) {
        .top-block-wrapper {
            margin: 0 auto;
            width: auto;
            padding: 40px 20px;
        }
    }
}

.request {
    display: block;
    position: relative;
    margin-top: 5px;
    padding: 0 0 12px 12px;
    width: 77%;
    input[placeholder] {color:$button_close_color;}
    ::-webkit-input-placeholder {color:$button_close_color;}
    ::-moz-placeholder          {color:$button_close_color;}/* Firefox 19+ */
    :-moz-placeholder           {color:$button_close_color;}/* Firefox 18- */
    :-ms-input-placeholder      {color:$button_close_color;}
    border-bottom: $form_border_hover;
    input[type=search] {
        font-family: inherit;
        line-height: inherit;
        color: $button_close_color;
        background-color: transparent;
        border: 0;
        padding: 0;
        width: 85%;
    }
    .form-actions {
        position: absolute;
        top: 0;
        right: 5px;
        input {
            display: block;
            border: 0;
            padding: 0;
            width: 20px;
            height: 21px;
            background: url(../images/icon-search_mobile.png) 0 0 no-repeat;
            text-indent: -9999px;

            &:active  {
                border-color: $line_active_color;
            }
        }
    }
    &:hover {
        border-color: $line_hover_color;
    }
    @media (max-width: $lg - 1px) {
        margin-top: 25px;
        width: 70%;
    }
    @media (max-width: $md - 1px) {
        margin-top: 5px;
        display: block;
        width: 92%;
        .request-icon {
            width: 20px;
            height: 20px;
            background: url(../images/request-icon-lg.png) 0 0 no-repeat;
            top: 0;
            right: 0;
        }
    }
    @media (max-width: $sm - 1px) {
        position: absolute;
        top: 0px;
        left: 15px;
        margin-top: 0px;
        width: 90%;
        .request-icon {
            width: 20px;
            height: 20px;
            background: url(../images/request-icon-lg.png) 0 0 no-repeat;
            top: 0;
            right: 0;
        }
    }
}

.result-wrapper {
    display: inline-block;
    width: 690px;
    overflow: hidden;
    padding-bottom: 30px;
    @media (max-width: $md - 1px) {
        width: 95%;
    }
    .messages-block {
        display: block;
        /* font-size: 1.71em;
        line-height: 1; */
        @media (max-width: $sm - 1px) {
            padding-bottom: 20px;
        }
        ul {
            counter-reset: list;
            padding-left: 0;
        }
        li {
            // color: $line_active_color;
            // padding-left: 6px;
            margin-bottom: 30px;
            list-style: none;
            position: relative;
            padding-left: 25px;

            &:before {
                counter-increment: list;
                content: counter(list) ". ";
                color: $line_active_color; /* Цвет маркеров */
                position: absolute;
                top: 0;
                left: 0;
                font-family: $bebas_font;
                font-size: 1.57em;
            }
        }
        .result-shell {
            display: block;
            // margin-top: 50px;
            font-family: $bebas_font;
            font-size: 1.57em;
            .result-item {
                line-height: 1;
                padding: 0 0 4px 6px;
                // margin-left: 6px;
                border-bottom: 2px solid $button_close_color;
                border-left: 2px solid $button_close_color;
                &:hover {
                    border-color: $line_active_color;
                }
            }
        }
        @media (max-width: $lg - 1px) {
            //font-size: 1.57em;
        }
        @media (max-width: $sm - 1px) {
            //font-size: 1.14em;
            padding-left: 0;
            .result-shell {
                margin-top: 30px;
            }
        }
    }
    .anons-item {
        margin-top: 15px;
        strong {
            color: $line_active_color;
        }
    }
}


.title-block,
.top-block {
    #breadcrumb {
        .breadcrumb {
            padding: 0 0 30px;
            color: rgb(211,211,211);
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            a, a:visited {
                color: rgb(211,211,211);
            }
            i {
                padding: 0 4px;
                color: $line_active_color;
            }

            @media (min-width: 1000px) {
                padding-left: 14px;
            }
        }
    }
}



#main {
    .main-text-block {
        .field-name-body {
           a,
            a:visited {
                color: #e8771a;
                text-decoration: underline;
            }
        }

    }
    .safety-wrapper {
        .safety-title {
            font-size: 2.14em;
        }
    }

    #breadcrumb {
        margin-top: 80px;
        font-size: 14px;
        padding-bottom: 0;
        color: $main_text;
        text-transform: uppercase;
        font-family: $bebas_font;
        font-weight: 400;
        letter-spacing: 0.5px;
        a {

            color: $line_hover_color;
        }
        i {
            padding: 0 4px;
            color: $line_active_color;
        }

        @media (max-width: $sm - 1px) {
            display: none;
        }

        @media (max-width: $md - 1px) and (min-width: $sm) {
            /* margin-left: 15px; */
        }
    }

    @media (min-width: $lg) {
        padding-bottom: 50px;
        padding-top: 40px;
        .main-text-block {
            max-width: 1350px;
            margin: 0 auto;
            padding: 0;
        }
    }


    @media (max-width: $lg - 1px) and (min-width: $md) {
        padding-top: 40px;
        padding-bottom: 50px;
        #block-views-team-block {
            margin-top: 0;
            .block-title h1 {
                font-size: 2.0em;
            }
            .team-block {
                .expert-card {
                    .expert-post {
                    height: 90px;
                    }
                }
            }
        }

        .second-container {
            padding-left: 30px;
        }
        .main-block-wrapper {
            margin-left: 0;
        }
        .main-text-block {
           //width: 960px;
            //font-size: 0.93em;
            //margin: 0 auto;
        }
        // .team-block-wrapper {
        //     margin-top: 50px;
        //     .block-title h1 {
        //         font-size: 2.0em;
        //     }
        //     .team-block {
        //         .expert-card {
        //             .expert-post {
        //             height: 90px;
        //             }
        //         }
        //     }
        // }
        .city-block-wrapper{
            .city-title {
                font-size: 1.57em;
            }
        }
        .safety-wrapper {
            .safety-title {
                dispaly: block;
                font-size: 2.0em;
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: $md - 1px) {
        padding-top: 35px;
        padding-bottom: 30px;
        .second-container {
            padding-left: 50px;
        }
        .main-text-block {
            width: 580px;
            margin: 0 auto;
            padding-bottom: 0px;
        }
        // .team-block-wrapper {
        //     width: 510px;
        //     margin-top: 30px;
        //     .team-block {
        //         .expert-card {
        //             .expert-post {
        //             height: 95px;
        //             }
        //         }
        //     }
        // }
        .city-block-wrapper{
            .city-title {
                font-size: 1.57em;
            }
        }
        .safety-wrapper {
            .safety-title {
                font-size: 2.0em;
                margin-bottom: 30px;
                display: block;
            }
        }
        .license-button:before{
            top: 17px;
            left: 82px;
        }
    }

    @media (min-width: $sm) and (max-width: $md - 1px) {
        padding-left: 20px;
        max-width: 510px;
    }

    @media (max-width: $md - 1px) and (min-width: 830px) {
        padding-bottom: 50px;
        .main-text-block {
            width: 740px;
            margin: 0 auto;
        }
    }

    @media (max-width: $sm - 1px) {
/*         padding-top: 25px; */
        padding-bottom: 50px;
        .second-container {
            padding: 0 10px;
        }
        .main-text-block {
            width: 100%;
        }
        // .team-block-wrapper {
        //     width: 280px;
        //     .block-title h1 {
        //         font-size: 2.0em;
        //     }
        //     .team-block {
        //         .expert-card {
        //             .expert-post {
        //             height: 75px;
        //             }
        //         }
        //     }
        // }
    }
}

.page-taxonomy-term {
    #breadcrumb {
        @media (max-width: $md - 1px) and (min-width: $sm) {
            margin-left: 15px;
        }
    }

    #main {
        @media (max-width: $sm - 1px) {
            padding-bottom: 0;
        }
    }
        .block-webform {
        margin: 60px 0;
        .form-item {
            margin-top: 0;
        }

        .user-agreement {
            font-size: 11px;
            line-height: 12px;
        }

        .webform-confirmation {
            padding: 0;
            margin: 20px 0 0;
        }

        #webform-component-usluga {
            margin: 0;
            display: block !important;
            input {
                padding: 0;
                border: none;
                font-family: $bebas_font;
                color: #A8A8A8;
                cursor: defualt;
                letter-spacing: 0.25px;
                font-weight: 300;
            }
        }
        @media (min-width: $sm) {
            //max-width: 540px;
            width: 100%;
            border: 1px solid rgb(233,233,233);
            padding: 30px;
            //float: left;

            .form-item,
            .form-actions {
                display: inline-block;
                vertical-align: top;
                width: 202px;
                margin: 0;
            }
            .form-item {
                margin: 0 20px 30px 0;
            }

            .form-actions {
                width: 202px;
                .form-button {
                    margin: 0;
                    height: 54px;
                    line-height: 54px;
                }
            }

            .block-title {
                margin: 0 0 5px !important;
            }
        }

        @media (min-width: $md) {
            //width: 720px;
            .user-agreement {
                left: 452px;
                bottom: 0;
                position: absolute;
                text-align: left;
                width: 204px;
            }
        }
    }
}

.page-node-7 #breadcrumb {
    display: none;
}

.page-portfolio {
    #main {
        padding-bottom: 0 !important;
        .main-text-block {
            padding-bottom: 0 !important;
        }

        #breadcrumb {
        display: none;
            margin-top: 0;
    margin: 0 10px 20px;
    .breadcrumb {
        padding: 0;
    }
    }
    }

    

    .page-title {
        color: #fff;
            margin: 0 10px 40px;

        @media (max-width: $sm - 1) {
            margin-bottom: 20px;
        }
        
    }
}

.page-node-7 {
    @media (min-width: $md) and (max-width: $lg - 1px){
        #main {
            padding-top: 0;
            .main-text-block {
                margin: 0;
                max-width: 860px;
            }
        }

        .field-name-body {
            padding-top: 40px;
            padding-left: 12px;
            border-left: 2px solid #E8771A;
            h2 {margin: 0 0 1em 0;}
        }
    }

    @media (min-width: $lg) {
        #main {
            padding-top: 0;
            width: 1280px;
            margin: 0 auto;
            .main-text-block {
                margin: 0;
                max-width: 860px;
            }
        }
        .field-name-body {
            padding-top: 40px;
            padding-left: 12px;
            max-width: 730px;
            border-left: 2px solid #E8771A;
            h2 {margin: 0 0 1em 0;}
        }


    }
}

// portfolio
.page-portfolio {
    background-color: #252525;

    .page-title {
        color: #fff;
        margin: 30px 0;
        @media (min-width: $md) {
            font-size: 3.2em;
            margin: 50px 0;
        }
    }

    #main {
        padding-top: 0 !important;

        .doc_icon-big {
            a {
                color: #fff;
                font-weight: 300;
                &:visited {
                    color: #fff;
                }

                &.fancybox-node:hover {
                    border-color: #E8771A;
                }

                &:hover,
                &:active {
                    background-color: $line_active_color;

                    svg {
                        path,rect, polygon {
                            fill: #fff;
                        }
                        polyline, line {
                            stroke: #fff;
                        }
                        
                    }
                }
            }
        }


        #block-block-30 {
            margin-bottom: 70px;
            clear: both;
            overflow: hidden;
            .content {
                color: #fff;
            }

            @media (min-width: $lg) {
                margin: 40px 50px 70px;
            }
        }

          #block-block-30 {
            @media (min-width: $lg) {
                margin: 10px 0px 70px;
            }
          }

    }

    @media (min-width: $sm) {
        #main {
            width: 100%;
            max-width: 100%;

            .main-text-block {
                width: 100%;
            }
        }
    }
}

.midlle-wrapper {
    .mcontent {
        position: relative;

        padding: 0 20px;
        max-width: 1350px;
        padding: 0 20px;
        margin: 0 auto;

        @media (min-width: $sm) {
            padding: 0 15px;
        }

        @media (min-width: $md) {
            padding: 0 34px;
        }

        @media (min-width: $lg) {
            padding: 0 55px;
        }
    }
}

.bg-1 {
    .serviceBg {
        position: fixed;
        top: 224px;
        // top:0;
        right: 30px;
        width: 123px;
        bottom: 0;
        background: url("../images/bg-1.jpg") no-repeat 0 0;
        background-size: 100% auto;
        z-index: -1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 205px;
          height: 132px;
          background: #fff;
        }

        @media (max-width: $md - 1px) {
            display: none;
        }

        @media (min-width: $lg - 1px) {
            width: 205px;
            right: 105px;
        }

    }
    #header .all-tabs-vector .vector-uslugi:nth-child(1) {
        text-decoration: none;
        border-color: $line_active_color;
    }
}

.bg-38 {
    .serviceBg {
        position: fixed;
        /*top: 47%;*/
        top:52%;
        right: -87px;
        width: 313px;
        bottom: 0;
        background: url("../images/bg-38.jpg") no-repeat 0 0;
        background-size: 100% auto;
            z-index: -1;

        @media (max-width: $md) {
            display: none;
        }

        @media (min-width: $lg - 1px) {
            width: 523px;

            right: -112px;

        }

    }

    #header .all-tabs-vector .vector-uslugi:nth-child(2) {
        text-decoration: none;
        border-color: $line_active_color;
    }
}

.bg-48 {
    .serviceBg {
        position: fixed;
        top: 130px;
        // top:0;
        right: 30px;
        width: 198px;
        bottom: 0;
        background: url("../images/66-8.png") no-repeat 0 0;
        background-size: 100% auto;
        z-index: -1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 331px;
          height: 130px;
          background: #fff;
        }

        @media (max-width: $md) {
            display: none;
        }

        @media (min-width: $lg - 1px) {
            width: 331px;
            right: 70px;
        }

    }
    #header .all-tabs-vector .vector-uslugi:nth-child(3) {
        text-decoration: none;
        border-color: $line_active_color;
    }
}

.fixed-service {
  .bg-38 {
    .serviceBg {
      top:40%;
    }
  }
  .bg-48 {
    .serviceBg {
      top:-150px;
    }
  }
  .bg-1 {
    .serviceBg {
      top: 0px;
    }
  }
}

.mCSB_container {
    margin-bottom: 10px;

    table {
        margin-bottom: 20px;
    }
}


.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 30px;
}

// services
.page-taxonomy-term {
    .vocabulary-catalog-uslug {
        max-width: 720px;
        ol {
            counter-reset: list;
            margin-left: 0;
            padding: 0;
            margin: 15px 0 30px;
            li {
                margin-bottom: 10px;
                list-style-type: none;
                &:before {
                    counter-increment: list;
                    content: counter(list) ". ";
                    color: $line_active_color; /* Цвет маркеров */
                    font-weight: 700;
                }
            }
        }

        ul {
            padding: 0;
            margin: 15px 0 30px;
            li {
                padding-left: 18px;
                margin-bottom: 10px;
                position: relative;
                list-style-type: none;

                &:before {
                    content: "";
                    font-size: 18px;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    height: 2px;
                    width: 10px;
                    background-color: #e8771a;
                }
            }
        }

        table {
            margin: 30px 0;
            max-width: 100%;
            tr {
                border: $table_in_border_color;
                border-top: none;
            }
            thead {
                td, th {
                    font-weight: 300;
                }
                tr {
                  &:first-of-type {
                    th:first-child {
                      font-size: 1em;
                    }
                  }
                }
            }

            tbody {
              tr {
                td {
                  &:last-of-type {
                    border-right: solid 1px #f4f4f4;
                  }
                }
              }
            }

            td, th {
            //width: 142px;
                font-size: 1.14em;
                padding: 16px 20px;
                text-align: left;
                border-bottom: solid 1px #f4f4f4;
            }

            th {
                color: $white_text;
                background-color: $main_text;
                border: 1px solid $main_text;
            }
            td:first-child {
                background-color: $line_color;
                font-size: 1em;
                border-bottom: solid 1px #e0e0e0;
                border-left: solid 1px #e0e0e0;
            }
            @media (max-width: $lg - 1px) {
                td, th {
                    font-size: 1.07em;
                }
                th:first-child {
                    font-size: 0.93em;
                }
                td:first-child {
                    font-size: 0.93em;
                }
            }
            @media (max-width: $md - 1px) {
                max-width: 703px;
                td, th {
                    font-size: 1.07em;
                }
                th:first-child {
                    font-size: 0.93em;
                }
                td:first-child {
                    font-size: 0.93em;
                }
            }
            @media (max-width: $sm - 1px) {
                max-width: 100%;
                td, th {
                    font-size: 1.07em;
                }
                th:first-child {
                    font-size: 0.93em;
                }
                td:first-child {
                    font-size: 0.93em;
                }
            }
        }

        @media (min-width: $sm) and (max-width: $md - 1px) {
            min-height: 500px;
            padding-left: 15px;
            max-width: 525px;
        }

        @media (min-width: $lg) {
            min-height: 700px;
        }
        @media (max-width: $md - 1px) {
            .taxonomy-term-description {
                img {
                    float: none !important;
                    margin: 0 !important;
                }
            }
        }
    }

    .works {
        margin: 70px -20px 50px;
        h2 {
            margin: 0 20px 30px;
        }

         @media (min-width: $sm) and (max-width: $md - 1px) {
            margin: 0 0 50px;
            width: 510px !important;
            h2 {
                margin: 70px 0 40px;
            }
        }

         @media (min-width: $md) {
            width: 715px;
            margin: 0 0 50px;
            h2 {
                margin: 80px 0 40px;
            }
         }
    }

    .service-video {
        display: none;
        .video {
            margin: 0 -15px;
            position: relative;
            div {
                position: relative;
            // width: 100%;
                height: 0;
                padding-bottom: 56.25%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100% !important;
                    height: auto;
                    min-height: 400px;
                    overflow: hidden;
                }

            }



            .play {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.6);
                button {
                    position: absolute;
                    display: block;
                    width: 100px;
                    height: 100px;
                    background: url(../images/video-button.png) 0 0 no-repeat;
                    top: 50%;
                    left: 50%;
                    margin-left:-50px;
                    margin-top:-25px;
                    cursor: pointer;
                    border: 0;
                }
                button:hover {
                    background: url(../images/video-button.png) 0 -100px no-repeat;
                }
                @media (max-width: $sm - 1px) {
                    button {
                        background: url(../images/video-button-60.png) 0 0 no-repeat;
                        width: 60px;
                        height: 60px;
                        margin-left:-30px;
                        margin-top:-30px;
                    }
                    button:hover {
                        background: url(../images/video-button-60.png) 0 -60px no-repeat;
                    }
                }
            }

        }


    }

    .slider {
        border-bottom: 3px solid $line_hover_color;
        clear: both;
        .item {
            text-align: center;
            background: #a8a8a8;
            img {
                /* width: 100%;
                max-height: 100%; */
                vertical-align: middle;
            }
        }
    }

    .bx-viewport {
        /* position: relative; */
        clear: both;
        height: 100%;


    }
    .bx-wrapper {
        position: relative;
        width: 715px;
        // height: 394px;
        margin-top: 34px;
        overflow: hidden;



        .bx-controls-direction {
            a {
                display: block;
                position: absolute;
                width: 40px;
                height: 50px;
                background: url(../images/slider-button.png) 0 0 no-repeat;
                top: 50%;
                margin-top: -25px;
                text-indent: -9999px;
            }

            a.bx-prev {
                left: 0;
                transform: scale(-1, 1);
            }
            a.bx-next {
                right: 0;
            }
        }

        .bx-pager {
            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
            height: 6px;
            overflow: hidden;
            background: #a8a8a8;
            background: -moz-linear-gradient(top, #a8a8a8 0%, #a8a8a8 50%, #ffffff 50%, #ffffff 100%);
            background: -webkit-linear-gradient(top, #a8a8a8 0%,#a8a8a8 50%,#ffffff 50%,#ffffff 100%);
            background: linear-gradient(to bottom, #a8a8a8 0%,#a8a8a8 50%,#ffffff 50%,#ffffff 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8a8a8', endColorstr='#ffffff',GradientType=0 );
        }

        .bx-pager-item {
            display: inline-block;
            width: 12%;
            height: 6px;
            margin-right: 4px;
            a {
                display: block;
                text-indent: -9999px;
                background-color: $line_active_color;
                cursor: default;
                height: 6px;

                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }
        }

        @media (max-width: $sm - 1px) {
/*             width: 300px;
height: 250px; */
      //       margin-left: -15px;
      // margin-right: -15px;
            margin-top: 25px;
            .slider-button {
                top: 98px;
            }
            .slider-indicator {
                width: 65px;
            }
            .slider-button-left {
                right: 260px;
            }
            .slider-button-right {
                right: 0;
            }
        }

        @media (max-width: $md - 1px) {
            // width: 510px;
            // height: 283px;
            // margin-left: -15px;
            // margin-right: -15px;
            margin-top: 25px;
            .slider-button {
                top: 115px;
            }
            .slider-indicator {
                width: 105px;
            }
            .slider-button-left {
                right: 470px;
            }
            .slider-button-right {
                right: 0;
            }
        }
    }
    .jobs-video {
        @media (max-width: $sm - 1px) {

        }
        @media (min-width: $sm) {
            margin: 60px 0 0;
            h3 {
                font-size: 22px;
                line-height: 26px;
                margin: 0 0 25px;
            }
        }

        @media (min-width: $lg) {
            margin: 60px 0 0;
            h3 {
                font-size: 24px;
                line-height: 28px;
                margin: 0 0 25px;
            }
        }

    }
    .jobs-block-video {
        position: relative;
        width: 715px;
        height: 397px;
        margin-top: 22px;
    /*     background: url(../images/antenna-1.png) -600px -100px no-repeat; */
        .preview {
            position: absolute;
            left:0;
            right: 0;
            bottom:0;
            top: 0;
            overflow: hidden;
            background-size: 100% auto;
            background-position: 50% 50%;

            img {
                width: 100%;
                height: auto;
                opacity: 0;
            }
            &:before {
                content: " ";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $main_text;
                opacity: 0.7;
            }
        }
        @media (max-width: $md - 1px) {
            margin-top: 28px;
            width: 510px;
            height: 283px;
        }
        @media (max-width: $sm - 1px) {
            width: auto;
            height: 250px;
            margin: 28px -20px 0;
        }

        .ytp-large-play-button {
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            background: url(../images/video-button.png) 0 0 no-repeat;
            top: 50%;
            left: 50%;
            margin-left:-50px;
            margin-top:-50px;
        }
        .jobs-block-video-button {
            .jobs-block-video-button-link {
                position: absolute;
                display: block;
                width: 100px;
                height: 100px;
                background: url(../images/video-button.png) 0 0 no-repeat;
                top: 50%;
                left: 50%;
                margin-left:-50px;
                margin-top:-50px;
            }
            .jobs-block-video-button-link:hover {
                background: url(../images/video-button.png) 0 -100px no-repeat;
            }
            @media (max-width: $sm - 1px) {
                .jobs-block-video-button-link {
                    background: url(../images/video-button-60.png) 0 0 no-repeat;
                    width: 60px;
                    height: 60px;
                    margin-left:-30px;
                    margin-top:-30px;
                }
                .jobs-block-video-button-link:hover {
                    background: url(../images/video-button-60.png) 0 -60px no-repeat;
                }
            }
        }
    }
}

/* #screen1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
}
 */
// #screen2 {
//     opacity: 0;
// }

// .animate_scroll {
//     #screen2 {
//         opacity: 1;
//     }
// }

// .fancybox-nav {
//     display: none;
// }

.block__front-service {
    overflow: hidden;
    /* position: fixed;
    top: 62px;
    left: 0;
    right: 0;
    bottom: 0; */
    z-index: 199;
    background: url("../images/123-1024-1-1.png") no-repeat 50% 20%;
    background-size: cover;
    overflow: hidden;
    min-height: 600px;
    @media (max-width: $md - 1px) {
        min-height: auto;
    }
    .pseudo-header {
        //display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        z-index: 210;

        .pcontent {
           /*  max-width: 1310px;  */
            margin: 0 auto;
            position: relative;
            clear: both;

            .contacts {
                position: absolute;
                top: 0;
                right: 80px;
                background: rgba(37, 37, 37, 0.7);
                padding: 20px 25px;
                color: #fff;
                clear: both;
                // overflow: hidden;
                text-transform: uppercase;
                font-family: $bebas_font;
                font-size: 16px;

                // * {
                //     float: left;
                // }

                .phone {
                    margin: 0 14px;
                }

                @media (min-width: $lg) {
                    right: 120px;
                    font-size: 20px;
                }
            }
        }

        .front-logo {
            width: 100px;
            height: 80px;
            background: rgba(255,255,255,0.5);

            &:before {
                content: "";
                position: absolute;
                width: 57px;
                height: 50px;
                top: 15px;
                left: 21px;
                background: url(../images/logo_bw.svg) no-repeat 50% 50%;
                background-size: contain;
            }
        }

        @media (max-width: $md - 1px) {
            display: none;
        }

        @media (min-width: $lg) {
            .front-logo {
                width: 120px;
                height: 100px;

                &:before {
                    width: 80px;
                    height: 70px;
                }
            }
        }
    }

    .content {
        padding: 62px 20px 195px;
        position: relative;
        height: 100%;
    }

    .service-item {
        .image {
            display: none;
        }

        .text {
            margin: 50px 0 0;
            position: relative;

            .title {
                padding: 0 0 15px 20px;
                border-bottom: 2px solid rgba(255,255,255,0.6);
                border-left: 2px solid rgba(255,255,255,0.6);
                color: #fff;
                font-size: 40px;
                font-family: $bebas_font;
                position: relative;
                z-index: 1;
                line-height: 36px;
                background: url(../images/icon_arr_small.png) no-repeat 100% 46px;

                span {
                    font-size: 20px;
                    display: block;
                }


            }

            .show-service-menu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 5;
                text-indent: -9999px;
            }

            @media (max-width: $md - 1px ){
                margin-top: 80px;
                .title {
                    font-size: 44px;
                    span {
                        font-size: 22px;
                    }
                }
            }

            @media (max-width: $sm - 1px ){
                margin-top: 50px;

                &:first-child {
                    margin-top: 20px;
                }

                .title {
                    font-size: 44px;
                    line-height: 44px;

                    background-position: 100% 50%;
                    span {
                        font-size: 22px;
                        line-height: 44px;
                    }
                }
            }
        }
    }

    @media (min-width: $sm) {
        .content {
            .service-item {
                width: auto;
                margin: 0 auto;
            }
        }
    }


    @media (min-width: $md) {
        background: none;
        margin: 0 -15px;
        // position: fixed;
        // left: 0;
        // right: 0;
        // top: 0;

        // bottom: 0;
        .content {
            width: 100%;
            position: relative;
            height: 100%;
            padding-top: 80px;


            .service-1 {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 33%;
                background: url("../images/1-2.png") no-repeat 50% 50%;
                background-size: cover;
            }

            .service-38 {
                position: absolute;
                left: 33%;
                top: 0;
                bottom: 0;
                width: 34%;
                background: url("../images/2-4_1.png") no-repeat 50% 50%;
                background-size: cover;
            }

            .service-48 {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 33%;
                background: url("../images/3-2.png") no-repeat 50% 50%;
                background-size: cover;
            }
        }

        .service-item {
            .text {
                margin: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                .tcontent {
                    width: 250px;
                    margin: 0 auto;
                }

                .title {
                    padding: 0;

                    border: 0;
                    display: block;
                    font-size: 44px;
                    line-height: 48px;
                    background: none;
                    span {
                        font-size: 22px;
                        line-height: 20px;
                    }
                }

                .show-service-menu {
                    width: 250px;
                    margin: 30px auto;
                    border: 1px solid rgba(255,255,255, 0.8);
                    text-align: center;
                    position: relative;
                    opacity: 1;
                    display: block;
                    text-indent: inherit;
                    color: #fff;
                    height: 60px;
                    line-height: 60px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255,255,255,0.3);
                    }
                }
            }
        }
    }


    @media (min-width: $lg) {
        .service-item {
            .text {
                margin: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                .tcontent {
                    width: 250px;
                    margin: 0 auto;
                }

                .title {
                    padding: 0;

                    border: 0;
                    display: block;
                    font-size: 64px;
                    line-height: 65px;
                    background: none;
                    span {
                        font-size: 30px;
                        line-height: 55px;
                    }
                }

                .show-service-menu {
                    margin: 35px 0;
                }
            }
        }
    }

    .scroll-to-screen {
        position: absolute;
        display: block;
        bottom: 100px;
        height: 60px;
        width: 30px;
        margin: 0 0 0 -15px;
        left: 50%;
        content: "";
        background: url("../images/icon-scroll.png") no-repeat 50% 0;
        // cursor: pointer;

        &:hover,
        &:active {
            background-position: 50% 100%;
        }

        @media (max-width: $sm - 1px) {
            display: none;
        }

        @media (min-width: $sm) {
            bottom: 175px;
        }

        @media (min-width: $md) {
            bottom: 80px;
        }
    }


    #block-block-29 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.7);
        .content {
            padding: 0 20px;
            margin: 0 auto;
            //width: 280px;
            border-left: 2px solid #e8771a;
            border-right: 2px solid #e8771a;
            font-family: $bebas_font;
            font-size: 16px;
            letter-spacing: 1px;
            color: #fff;

            span {
                color: $line_active_color;
            }

            p {
                margin: 0;
            }

            @media (min-width: $sm) {
                width: 600px;
                margin: 0 auto;
            }

            @media (min-width: $sm) and (max-width: $md - 1px) {
                font-size: 18px;
                line-height: 24px;
            }



        }

        @media (min-width: $md) {
            padding: 10px 20px;
            .content {
                width: 760px;
            }
        }

        @media (min-width: $lg) {
            padding: 15px 20px;
                .content {
                    font-size: 18px;
                    line-height: 20px;
                    width: 775px;
                }
            }

    }

}

.block__popup-service {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    // bottom: 0;
    background: #252525;
    color: #fff;
    height: 100%;
    overflow-y: auto;


    background: url("../images/city_light.png") repeat-x 50% 100% #252525;

/*     &:before {
    content: "";
    width: 64px;
    height: 55px;
    background: url("../images/logo.png") no-repeat 50% 50%;
    background-size: cover;
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 0;
}
 */.bcontent {
        padding: 100px 20px;
        position: relative;
    }
    .close-popup {
        background: url(../images/cross-close.png) 50% 50% no-repeat;
        background-color: #3B3B3B;
        position: absolute;
        right: 0;
        top: 0;
        width: 60px;
        height: 60px;
        z-index: 20;

   /*      &:hover {
       opacity: 0.6;
   } */

        @media (min-width: $md) {
            width: 80px;
            height: 80px;
            cursor: pointer;
        }
    }

    .logo {
        display: block;
        a {
            position: absolute;
            left: 0;
            height: 50px;
            margin-left: 0px;
            top: 0;
            background-size: contain;
            width: 60px;

            img {
                display: none;
            }

            @media (max-width: $md - 1) {
                top: 5px;
                background: url(../images/logo_color.svg) no-repeat 0 0;
                background-size: contain;
                margin-left: 30px;
            }
        }
    }

    .image {
        display: none;
    }

    .title {
        padding: 0 0 25px 0;
        border-bottom: 2px solid rgba(255,255,255,0.8);
        color: #fff;
        font-size: 40px;
        font-family: $bebas_font;
        position: relative;
        z-index: 1;
        line-height: 36px;

        span {
            font-size: 20px;
            display: block;
        }

        @media (min-width: $sm) and (max-width: $md - 1px) {
            max-width: 280px;
        }
    }

    .menu-block {
        position: relative;
        z-index: 5 !important;
    }

    .item-elem {
        margin: 55px 0 0;
        border-left: 2px solid #666;
        border-bottom: 2px solid #666;
        padding-left: 10px;
        padding-bottom: 8px;
        line-height: 22px;


        .item-title a {
            color: #fff;
            font-size: 22px;
            line-height: 26px;
            font-family: $bebas_font;
            &:visited {
                color: #fff;
            }

            @media (min-width: $md) {
                font-size: 24px;
                max-width: 45%;
            }

            @media (min-width: $lg) {
                font-size: 30px;
                line-height: 36px;
            }
        }

        span {
            text-transform: uppercase;
            font-family: $bebas_font;
            font-size: 22px;
            display: block;
            background: url("../images/icon-arr-dropdown.png") no-repeat 100% 10px;
            cursor: pointer;

            @media (min-width: $md) {
                font-size: 24px;
            }

            @media (min-width: $lg) {
                font-size: 30px;
                line-height: 36px;
            }
        }

        @media (min-width: $sm) and (max-width: $md - 1px) {
            max-width: 280px;
            &.active {
                max-width: 100%;
            }
        }

        @media (min-width: $md) {
            max-width: 482px;
            &.active {
                max-width: 100%;
            }
        }

        @media (min-width: $lg) {
            max-width: 482px;
            &.active {
                max-width: 100%;
            }
        }

        .children_list {
            padding-top: 10px;
            line-height: 20px;
            display: none;

            .item {
                display:inline-block;
                margin: -2px 3px 3px 0;
                max-width: 100%;
                a {
                    display: block;
                    height: 50px;
                    line-height: 28px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    background: #3b3b3b;
                    text-decoration: none;
                    color: #fff;
                    padding: 10px 15px;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &:hover {
                        background: #666;
                    }
                }
            }
        }

        &:hover {
            border-color: #e8771a;
        }

        &.active {
            border-bottom: 0;
            border-left: 2px solid #e8771a;
            max-width: 100%;
            padding-bottom: 0;

            span {

                border-bottom: 1px solid #4c4c4c;
                margin: 0 0 5px;
                background-position: 100% -35px;
            }

            .children_list {
                display: block;
            }

            @media (min-width: $sm) {
                span {
                    max-width: 270px;
                }
            }

            @media (min-width: $md) {
                span { padding-bottom: 6px;
                    max-width: 380px;
                }
            }


            @media (min-width: $lg) {
                span {
                    padding-bottom: 6px;
                    max-width: 470px;
                    font-size: 30px;
                    line-height: 36px;
                    margin-bottom: 20px;
                }
            }

        }
    }

    @media (min-width: $sm) {

        .bcontent {
            padding: 100px 30px 120px;
        }
        .logo {
            a {
                &:before {
                    display: block;
                    text-decoration: none;
                    position: absolute;
                    left: 30px;
                    top: 5px;
                    width: 60px;
                    height: 50px;
                    background: url("../images/logo_color.svg") no-repeat 0 0;
                    background-size: contain;
                }
            }
        }

/*         .item-elem {
    max-width: 100%;
} */

        &#service-1:after {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background: url("../images/33-6.png") no-repeat 100% 0%;
            opacity: 0.5;
            width: auto;
            height: auto;
            z-index: 0;
        }

        &#service-38:after {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background: url("../images/55-7.png") no-repeat 100% 100%;
            opacity: 0.5;
            width: auto;
            height: auto;
            z-index: 0;
        }

        &#service-48:after {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background: url("../images/66-7.png") no-repeat 100% 0%;
            opacity: 0.5;
            width: auto;
            height: auto;
            z-index: 0;
        }
    }


    @media (min-width: $md) {


        .bcontent {
            padding-left: 350px;
            padding-top: 1px;
            padding-bottom: 80px;
            min-height: 100%;
        }

        .menu-block {
            //padding: 100px 50px;
        }

        &#service-1 {
            //background: url("../images/123-1024-1-1.png") no-repeat 0 50%;
            .title {
                margin: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                width: 300px;
                top: 0;
                bottom: 0;
                padding-left: 45px;
                background: url("../images/123-1024-1-1.png") no-repeat 0 50%;
                background-size: cover;
                font-size: 44px;

                .tcontent {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        left: 0;
                        width: 255px;
                        height: 2px;
                        background: #fffc;
                    }
                    span {
                        font-size: 22px;
                    }
                }
            }
        }

        &#service-48 {
            //background: url("../images/123-1024-1-1.png") no-repeat 0 50%;
            .title {
                margin: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                width: 300px;
                top: 0;
                bottom: 0;
                padding-left: 45px;
                background: url("../images/123-1024-3-1.png") no-repeat 0 50%;
                background-size: cover; font-size: 44px;

                .tcontent {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        left: 0;
                        width: 255px;
                        height: 2px;
                        background: #fffc;
                    }
                    span {
                        font-size: 22px;
                    }
                }
            }
        }

        &#service-38 {
            //background: url("../images/123-1024-1-1.png") no-repeat 0 50%;
            .title {
                margin: 0;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                width: 300px;
                top: 0;
                bottom: 0;
                padding-left: 45px;
                background: url("../images/123-1024-2-1.png") no-repeat 0 50%;
                background-size: cover;
                 font-size: 44px;

                .tcontent {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        left: 0;
                        width: 255px;
                        height: 2px;
                        background: #fffc;
                    }
                    span {
                        font-size: 22px;
                    }
                }
            }
        }

        .logo {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            height: 80px;
            background: rgba(255, 255, 255, 0.5);
            z-index: 2;
            margin: 0;
            a {
                display: block;
                width: 100px;
                height: 80px;
                &:before {
                    content: "";
                    position: absolute;
                    width: 57px;
                    height: 50px;
                    top: 15px;
                    left: 21px;
                    background: url(../images/logo_bw.svg) no-repeat 50% 50%;
                    background-size: contain;
                }
            }
        }
    }

    @media (min-width: $lg) {
        .logo {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 100px;
            background: rgba(255, 255, 255, 0.5);
            z-index: 2;
            a {
                display: block;
                width: 120px;
                height: 100px;
                &:before {
                    content: "";
                    position: absolute;
                    width: 80px;
                    height: 70px;
                    top: 13px;
                    left: 20px;
                    background: url(../images/logo_bw.svg) no-repeat 50% 50%;
                    background-size: contain;
                }
            }
        }

        .close-popup {
            width: 110px;
            height: 100px;
        }

        &#service-48,
        &#service-38,
        &#service-1 {
            .bcontent {
                padding-left: 500px;
            }

            .title {
                font-size: 64px;
                line-height: 65px;
                width: 450px;

                .tcontent {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -25px;
                        left: 0;
                        width: 405px;
                        height: 2px;
                        background: #fffc;
                    }
                    span {
                        font-size: 30px;
                    }
                }
            }
        }
    }

}

.page-kontakty {
    background-color: #252525;

    #main {
        padding: 0 30px 50px !important;
        max-width: 100%;

        .main-text-block {
            width: auto;
            max-width: 100%;
        }

        .contacts-block {
            .mail-text {
                font-size: 40px;
                line-height: 44px;
            }

            .contact-item {
                //padding-top: 0 !important;
            }
        }

        @media (max-width: $sm - 1px ) {
            padding: 0 0 50px !important;
        }
    }
    #breadcrumb {
        display: none !important;
    }
    #footer {
        border-top: 2px solid #3b3b3b;
    }

    @media (min-width: $lg) {
        #main {
            padding-left: 38px;
        }
    }
}

.page-search {
    .title-block {
        background: url(../images/city_light.png) 50% 100% no-repeat #252525;
        height: auto;
        .title-block__container {
            padding-top: 25px;
            padding-bottom: 120px;
            height: 240px;


            @media (min-width: $md) {
                padding-top: 40px;
            }
            h1 {
                margin: 0 0 25px;
                line-height: 1em;
            }
            .count-results {
                text-transform: uppercase;
                margin: 0 0 40px;
                font-family: $bebas_font;
                font-size: 20px;
                line-height: 22px;

                span {
                    color: $line_active_color;
                }
            }

            .search-form {
                display: block;
                margin-top: 40px;
                border-bottom: 2px solid #666;
                margin-bottom: 0;
                max-width: 345px;
                position: relative;

                .form-item {
                    label {
                        display: none;
                    }

                    input[type=text] {
                        border: 0;
                        background: none;
                        outline: none;
                        height: 35px;
                        line-height: 35px;
                        padding: 0;
                        color: #d3d3d3;
                    }
                }

                input[type=submit] {
                    position: absolute;
                    right: 0;
                    width: 35px;
                    height: 35px;

                    display: block;
                    border: 0;
                    padding: 0;
                    background: url(../images/request-icon.png) 50% 50% no-repeat;
                    text-indent: -9999px;
                    top: 0;
                }
            }
        }

    }

    @media (min-width: $md) {
        .title-block {
            position: relative;
            height: 240px;
            padding-left: 0;
            padding-right: 0;
            color: #fff;

            // overflow: hidden;
            background-size: auto 90%;

            .top-block-wrapper {
                padding-left: 12px;
                border-left: 2px solid #E8771A;
                height: auto;
            }
        }
    }
}

.front {
    background: #252525;

    .content-wrapper {
        padding-top: 0 !important;
    }

    #header {
        display: none;
        &.scrolled {
            display: block;
        }

        @media (max-width: $md - 1px) {
            display: block;
        }
    }

    .page-title {
        display: none;
    }
}

#footer {
    .container {
            position: relative;

            .callback-block {
                width: 20px;
                height: 22px;
                margin-top: 0;
                padding: 0;
                vertical-align: middle;
                display: inline-block;
                .callback-link {
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 22px;
                    background: url(../images/telefon-icon.png) 0 0 no-repeat;
                    top: 0;
                    left: 0;
                    text-decoration: none;
                }
                &:hover {
                    .callback-link {
                        background-position: 0 -22px;
                    }
                }
                .callback-link {
                    @media (max-width: $lg - 1px) {
                        margin-top: 0;
                    }
                    @media (max-width: $md - 1px) {
                        margin-top: 0;
                    }
                }

                

                @media all and (max-width: $md - 1) {
                    position: absolute;
                        bottom: -32px;
                    left: 15px;
                }

                @media (max-width: $sm - 1) {
                    position: absolute;
                    top: initial;
                    bottom: 0px;
                    left: 15px;
                    padding: 0;
                }
            }

            .order-link {
                position: absolute;
    bottom: -22px;
    left: 42px;
     .content {
                    a, a:visited {
                        text-decoration: none;
                        color: $line_active_color;

                        background: -moz-linear-gradient(left, $line_active_color, $line_active_color 60%, transparent 60%);
                        background: -ms-linear-gradient(left, $line_active_color, $line_active_color 60%, transparent 60%);
                        background: -o-linear-gradient(left, $line_active_color, $line_active_color 60%, transparent 60%);
                        background: -webkit-gradient(linear, 0 0, 100% 0, from($line_active_color), color-stop(0.6, $line_active_color), color-stop(0.6, transparent));
                        background: -webkit-linear-gradient(left, $line_active_color, $line_active_color 60%, transparent 60%);
                        background: linear-gradient(left, $line_active_color, $line_active_color 60%, transparent 60%);
                        background-size: 4px 1px;
                        background-repeat: repeat-x;
                        background-position: 0 100%;

                        &:hover,
                        &:active {
                            background: none;
                        }
                    }
                }

                @media (min-width: $sm - 1px) {
                    left: 192px;
                    bottom: -28px;
                }
            }
        }
}


#screen1 {
    z-index: 10;
    position: relative;

    .iScrollLoneScrollbar {
      display: none;
    }
}

#screen2 {
    height: auto !important;
    position: relative;
    z-index: 1;
    //padding-bottom: 240px;

    .iScrollLoneScrollbar {
      display: none;
    }

    // #footer {
    //     bottom: initial;
    //     position: relative;
    // }

    /* @media (min-width: $sm) {
        padding-bottom: 170px;
    }

    @media (min-width: $md) {
        padding-bottom: 70px;
    }

    @media (min-width: $lg) {
        padding-bottom: 46px;
    } */
    * {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .block-title {
        background: url("../images/city_light.png") repeat-x 0% 104% #252525;
        height: 280px;
        position: relative;
        .content {
            padding: 100px 20px 0 40px;
            color: #fff;
            text-transform: uppercase;
            font-family: $bebas_font;

            h2 {
                font-size: 28px;
                line-height: 28px;
                margin: 0 0 20px;
            }

            h3 {
                font-size: 22px;
                line-height: 22px;
                margin: 0;
                color: rgb(211,211,211);

                span {
                    color: $line_active_color;
                }
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 100px;
            left: 20px;
            bottom: 0;
            border-left: 2px solid #e8771a;
        }
    }
    .block-portfolio {
        background: url("../images/city_dark.png") repeat-x 0% 100% #252525;
        height: 220px;
        position: relative;
        .content {
            padding: 85px 20px 0 40px;
            color: #fff;
            text-transform: uppercase;

            h2 {
                /* font-size: 28px;
                line-height: 28px; */
                margin: 0 0 20px;
                font-size: 44px;
                line-height: 40px;

                a {
                 color: #fff;
                 display: inline-block;
                 text-decoration: none;
                 border-left: 2px solid rgba(255,255,255, 0.6);
                 border-bottom: 2px solid rgba(255,255,255, 0.6);
                 padding: 0 0 12px 12px;

                 &:visited {
                    color: #fff;
                 }
                 &:hover,
                     &:active {
                        border-color: #e8771a;
                     }
                }
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            border-left: 2px solid #e8771a;
        }
    }


    .block-facts {
        background: url("../images/bg_portfolio.jpg") no-repeat 0% 50%;
        background-size: cover;
        position: relative;
        z-index: 0;
        video {
            display: none;
        }
        .content {
            padding: 50px 10px 50px 40px;
            color: #fff;
            .facts-list {
                .item {
                    margin: 0 0 30px;
                    .title {
                        font-size: 44px;
                        line-height: 44px;
                        font-family: $bebas_font;
                    }

                    .text {
                        font-size: 14px;
                    }
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            border-left: 2px solid #e8771a;
        }
    }

    .block-priorities {
        position: relative;
        height: 260px;
        background: #202020;
        z-index: 10;
        h2 {
            position: absolute;
            left: 0;
            top: -40px;
            background: #e8771a;
            font-size: 30px;
            padding: 22px 45px;
            height: 80px;
            color: #fff;
            margin: 0;
            z-index: 1000;
        }
        .content {
            padding: 90px 20px 35px 40px;
            color: #fff;
            position: relative;
            z-index: 2;
            .priorities-list {
                .item {
                    margin: 15px 0 30px;
                    a {
                        font-size: 24px;
                        line-height: 16px;
                        font-family: $bebas_font;
                        text-transform: uppercase;
                        color: #fff;
                         display: inline-block;
                         text-decoration: none;
                         border-left: 2px solid #666;
                         border-bottom: 2px solid #666;
                         padding: 0 0 12px 12px;
                         &:visited {
                            color: #fff;
                         }
                         &:hover,
                     &:active {
                        border-color: #e8771a;
                     }

                    }
                }
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            border-left: 2px solid #e8771a;
        }
        &:before {
            content: "";
            position: absolute;
            background: url("../images/bg_malyar.jpg") no-repeat -40px 0%;
            top: 0;
            right: 0px;
            bottom: 0;
            left: 160px;
        }
    }

    .block-team {
        background: #252525;
        height: 230px;
        position: relative;
        .content {
            padding: 115px 20px 0 40px;
            color: #fff;
            text-transform: uppercase;
            position: relative;
            z-index: 2;

            h2 {
                font-size: 44px;
                line-height: 30px;
                margin: 0;


                a {
                 color: #fff;
                 display: inline-block;
                 text-decoration: none;
                 border-left: 2px solid #666;
                 border-bottom: 2px solid #666;
                 padding: 0 0 14px 14px;
                 &:visited {
                    color: #fff;
                 }

                 &:hover,
                 &:active {
                    border-color: #e8771a;
                 }
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            border-left: 2px solid #e8771a;
        }
        &:before {
            content: "";
            position: absolute;
            background: url("../images/bg_team.png") no-repeat 100% 0%;
            background-size: contain;
            top: -30px;
            right: -58px;
            bottom: 0px;
            left: 20px;
            border-bottom: 2px solid #e8771a;
        }
    }

    .block-blog {
        background: url("../images/bg_blog.jpg") no-repeat 50% 0%;
        position: relative;
        background-size: cover;
        height: 300px;
        .content {
            padding: 212px 20px 40px 40px;
            height: 300px;
            color: #fff;
            text-transform: uppercase;
            position: relative;
            z-index: 2;

            h2 {
                font-size: 44px;
                line-height: 30px;
                margin: 0;


                a {
                 color: #fff;
                 display: inline-block;
                 text-decoration: none;
                 border-left: 2px solid #666;
                 border-bottom: 2px solid #666;
                 padding: 0 0 14px 14px;
                     &:visited {
                        color: #fff;
                     }
                     &:hover,
                     &:active {
                        border-color: #e8771a;
                     }
                }
            }


        }

        .jobs-block-video-button-link {
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            background: url(../images/video-button.png) 0 100% no-repeat;
            top: 50%;
            left: 50%;
            margin-left:-50px;
            margin-top:-50px;
        }
        .jobs-block-video-button-link:hover {
            background: url(../images/video-button.png) 0 0 no-repeat;
        }
        @media (max-width: $sm - 1px) {
            .jobs-block-video-button-link {
                background: url(../images/video-button-60.png) 0 -60px no-repeat;
                width: 60px;
                height: 60px;
                margin-left:-30px;
                margin-top:-30px;
            }
            .jobs-block-video-button-link:hover,
            .jobs-block-video-button-link:active {
                background: url(../images/video-button-60.png) 0 0 no-repeat;
            }
        }

    }

    @media (min-width: $sm) {
        // width: 630px;margin: 0 auto;
        .block-facts {
            .facts-list {
                .item {
                    display: inline-block;
                    width: 49%;
                    vertical-align: top;
                }
            }
        }
        .block-priorities {
            float: left;
            width: 45%;

            .content {
                padding-top: 90px;
            }

            &:after {
                content: "";
                position: absolute;
                left: 20px;
                bottom: 0;
                right: 0;
                border-bottom: 2px solid #e8771a;
            }

            &:before {
                left: inherit;
                width: 130px;
            }
        }
        .block-team {
            float: left;
            width: 55%;
            height: 260px;

            .content {
                padding-top: 105px;
            }

            &:after {
                display: none;
            }

            &:before {
                left: 0; right: 0;
            }
        }

        .block-blog {
            clear: both;
            height: 340px;
            .content {
                height: 340px;
                padding: 205px 20px 80px 40px;
            }
        }
    }

    @media (min-width: $md) {
        .block-title {
            float: left;
            width: 56%;
            height: 310px;
            .content {
                padding: 150px 60px;

                h2 {
                    font-size: 30px;
                }
            }

            &:before {
                left: 30px;
                top: 150px;
            }
        }

        .block-portfolio {
            float: left;
            width: 44%;
            height: 310px;
            .content {
                padding-top: 150px;

                h2 {
                    font-size: 44px;
                    margin: 4px 0 0;
                    a {
                        padding: 0 0 14px 14px;
                    }
                }
            }

            &:before {
                display: none;
            }
        }

        .block-facts {
            clear: both;
            height: auto;
            z-index:-1;
            &:before {
                left: 30px;
            }

            video {
                display: block;
                width: 100%;
                height: auto;
                z-index: 0;
            }

            .content {
                padding: 0 30px 0 60px;
                text-align: center;
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                .facts-list {
                    .item {
                        text-align: left;
                        display: inline-block;
                        max-width: 158px;
                        vertical-align: top;
                        margin: 0 0 0 30px;
                        width: auto;

                        &:first-child {
                            margin-left: 0;
                        }


                        .title {
                            font-size: 70px;
                            margin: 0 0 30px;
                        }

                        .text {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .block-priorities {
            width: 50%;
            .content {
                padding-left: 60px;
                padding-top: 105px;
            }
            &:after {
                left: 30px;
            }
            &:before {
                left: inherit;
                width: 315px;
            }

            h2 {
                padding-right: 55px;
                padding-left: 55px;
            }
        }

        .block-team {
            width: 50%;
        }

        .block-blog {
            height: 345px;
            .content {
                height: 345px;
                padding-top: 240px;
            }
        }
    }

    @media (min-width: $lg) {
        /*max-width: 1350px;*/
        max-width: 100%;
        margin: 0 auto;

        .fp-scroller {
          max-width: 1350px;
          margin: 0 auto;
          overflow:hidden;
        }

        .block-title {
            height: 340px;
            .content{
                padding-top: 180px;
            }
            &:before {
                top: 180px;
            }
        }
        .block-portfolio {
            height: 340px;
            .content{
                padding-top: 180px;
            }
        }
        .block-priorities {
            width: 58%;
            height: 300px;
            .content {
                padding-top: 135px;
            }
            &:before {
                left: inherit;
                width: 455px;
                background-position: 0 0;
            }

        }
        .block-team {
            height: 300px;
            width: 42%;
        }

        .block-facts {
            height: auto;

            .content {
                // padding-top: 216px;

                .facts-list {
                    .item {
                        margin-left: 80px;
                        max-width: 185px;
                        &:first-child {
                            margin: 0;
                        }

                        .title {
                            font-size: 80px;
                            margin: 0 0 30px;
                        }

                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .block-blog {
            height: 450px;
            .content {
                height: 450px;
                padding-top: 340px;
            }
        }
    }
}

.content-wrapper {
    @media (min-width: $md) {
        padding-top: 70px;
    }

    @media (min-width: $lg) {
        padding-top: 100px;
    }
}

#header {

    @media (min-width: $md) and (max-width: $lg - 1px) {
        height: 70px;
        .container {
            padding: 0 30px;

            .row {
                margin-top: 7px;

                .all-tabs-vector {
                    vertical-align: middle;
                }

                .header-col-right {
                    padding-top: 16px;
                }
            }
        }
    }

}

.fixed-service {
    #header {
        height: 70px;

        .row {
            margin-top: 7px;

            .all-tabs-vector {
                vertical-align: middle;
            }

            .header-col-right {
                padding-top: 16px;
            }
        }
    }

    @media (min-width: $lg) {
        #header {
            .logo {
                width: 59px;
                height: 50px;
                margin-right: 30px;
            }
            .row {
                margin-top: 7px;

                .all-tabs-vector {
                    vertical-align: text-bottom;
                    .vector-uslugi {
                        /*font-size: 16px;
                        line-height: 16px;*/
                        font-size: 18px;
                        line-height: 18px;
                        padding: 0 0 5px 8px;
                        margin-left: 30px;
                    }
                }

                .header-col-right {
                    /*padding-top: 14px;*/
                    padding-top: 10px;

                    * {
                        /*font-size: 16px !important;*/
                        font-size: 18px !important;
                        line-height: 20px;
                    }

                    #block-block-24 {
                        margin-top: 0px;
                    }

                    .unselected-city {
                        top: 32px;
                    }
                }
            }
        }

        .top-block-wrapper {
            max-width: 1350px;
            padding: 0 20px 0 34px;
        }
    }
    @media (min-width: $md) {
        &.page-taxonomy {
            .top-block {
                position: fixed;
                left: 0;
                right: 0;
                top: 70px;
                background: #252525;
                background-image: none !important;
                padding-top: 20px;
                padding-bottom: 20px;
                z-index: 100;

                /* .top-block-wrapper {
                    padding-left: 30px;
                } */

                .container {

                }

                .border-left {
                    padding: 0;
                    border-left: 0;
                    position: relative;

                    h1 {
                        padding-left: 12px;
                        border-left: 2px solid #E8771A;
                        margin: 0 370px 0 0;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .top-button {
                        position: absolute;
                        right: 26px;
                        top: -10px;
                        height: 40px;
                        line-height: 38px;
                        margin: 0;
                        background: #303030;
                        border-color: #515151;

                        &:after {
                            display: none;
                        }

                        &:hover,
                        &:active {
                            border-color: #e8771a;
                            background: none;
                        }

                        @media (min-width: $md) {
                            right: 10px;
                        }

                        @media (min-width: $lg) {
                            right: 8px;
                        }
                    }

                    .top-block-menu {
                    margin: 0;
                        .top-block-list {
                            width: auto;
                            position: absolute;
                            top: 0px;
                            /*right: 320px;*/
                            right:280px;

                            h3 {
                                margin: 0;
                                font-size: 20px;
                                line-height: 24px;
                                color: rgb(168,168,168);

                            }

                            &:after {
                                /*right: -45px;*/
                                right: -30px;
                                top: 8px;
                            }

                            .bottom-line {
                                display: none;
                            }

                            &.active {
                                h3 {
                                    color: #fff;
                                }
                            }
                        }

                        .list-wrapper {
                            margin-top: 20px;
                            border-top: 2px solid #303030;
                            padding: 20px 0 10px;
                        }
                    }
                }
            }
        }
    }
}

.page-node-7 {
  .title-block {
    .top-block-wrapper {
      max-width: 1240px;

      @media (max-width: 1398px) {
        float: left;
        margin-left: 75px;
      }

      @media (max-width: $lg) {
        margin-left: 50px;
      }
    }
  }
}

.page-node-26,
.page-node-44 {
  .content {
    max-width: 720px;
  }
}

.menu-row-top {
  @media (max-width: $md) {
    max-width: 100%;

    .menu-col-right {
      min-height:60px;

      .menu-close {
        position: absolute !important;
        top: 0;
        right: -15px;
      }
    }

  }
}

.tablet {
  #screen2 {
    .block-facts {
        background: url("../images/bg_portfolio.jpg") no-repeat 0% 50%;
        background-size: cover;
        position: relative;
        z-index: 0;
        min-height: 400px;
        video {
          display: none;
        }
    }
  }
}

.seo-text__front {
    
    .content {
        padding: 20px;
        color: #fff;
        max-width: 1350px;
        margin: 0 auto;
        ol {
            counter-reset: list;
            margin-left: 0;
            padding: 0;
            margin: 15px 0 30px;
            li {
                margin-bottom: 10px;
                list-style-type: none;
                &:before {
                    counter-increment: list;
                    content: counter(list) ". ";
                    color: $line_active_color; /* Цвет маркеров */
                    font-weight: 700;
                }
            }
        }

        ul {
            padding: 0;
            margin: 15px 0 30px;
            li {
                padding-left: 18px;
                margin-bottom: 10px;
                position: relative;
                list-style-type: none;

                &:before {
                    content: "";
                    font-size: 18px;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    height: 2px;
                    width: 10px;
                    background-color: #e8771a;
                }
            }
        }
    }

    @media all and (min-width: $md) {
        .content { padding: 30px 40px; }
    }
}

.service-child {
    margin: 30px 0;

    a,
    a:visited {
            color: #E8771A;
    text-decoration: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8US5VDwAFwgH62SBgegAAAABJRU5ErkJggg==);
    background-repeat: repeat-x;
    background-position: center 1.25em;
    }

    @media (min-width: $md) {
        margin: 50px 0;
    }
}
.taxonomy-term-description {
    a,a:visited {
        color: $line_active_color;
    }
}
.page-node-26 {
    #breadcrumb {
        display: none;
    }
}
.page-taxonomy-term {
.view-more {
    clear: both;
    overflow: hidden;
    h2 {
        margin: 0 0 40px !important;
    }
    .service-item {

        margin: 0 0 20px;

        a, a:visited {
            display: block;
            text-decoration: none;
            
            position: relative;
            font-size: 22px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: #fff;
            font-family: $bebas_font;
            span {
                padding: 24px 20px;

                height: 180px;
                display: block;
                background-position: 50% 0;
                background-size: cover;
                background-repeat: no-repeat;
                text-shadow: 0 0 1px #2f2f2f;
            }
            &:hover,
            &:active {
                span {
                    color: $line_active_color;
                }
            }
        }

        @media (min-width: $sm) {
            float: left;
            width: 50%;

            &:nth-child(even) {
                padding-right: 10px;
            }

            &:nth-child(odd) {
                padding-left: 10px;
            }
        }
    }
}
}