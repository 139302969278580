$lg: 1350px;
$md: 1024px;
$sm: 640px;
$xs: 320px;

$main_text: #252525; //R: 37, G: 37, B: 37
$white_text: #fff;
$footer_text_color: #929292;
$inline_border_color: #EAEAEA;
$number_color: #1A8BB2;
$link_color: #000;
$link_hover: #127899;
$link_visited: #375d81;

$gray_fon: #3B3B3B; //R: 59, G: 59, B: 59
$line_hover_color: #A8A8A8; //R: 168, G: 168, B: 168

$table_text_color: #53728a;
$table_out_border_color: 1px solid #dde3e8;
$table_in_border_color: 1px solid #D3D3D3; //R: 211, G: 211, B: 211

$form_text_color: #7C7C7C; //R: 124, G: 124, B: 124
$form_border: 2px solid #A8A8A8; //R: 168, G: 168, B: 168
$form_border_hover: 2px solid #666; //R: 102, G: 102, B: 102
$form_hover_color: #666; //R: 102, G: 102, B: 102
$form_hover: #91BED4;

$line_color:#E9E9E9; //R: 233, G: 233, B: 233
$line_active_color: #E8771A;

$button_close_color: #D3D3D3; //R: 211, G: 211, B: 211
$select_city_color: #202020;

$gray_fon_info_item: #303030; //R: 48, G: 48, B: 48
$gray_message: #949494; //R: 148, G: 148, B: 148
$bebas_font: 'BebasNeue', "Helvetica Neue", Helvetica, Arial, sans-serif;