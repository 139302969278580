@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/BebasNeue-Regular.eot'); /* IE9 Compat Modes */
  src: local('BebasNeue-Regular'), local('BebasNeue-Regular'),
       url('../fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/BebasNeue-Regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/BebasNeue-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/BebasNeue-Regular.svg') format('svg'); /* Legacy iOS */
}
/* open-sans-300 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v15-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v15-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/open-sans-v15-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
       url('../fonts/open-sans-v15-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v15-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/open-sans-v15-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v15-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
