@import "vars.scss";
@import "fonts.scss";
@import "new.scss";

*:focus, *:visited {
    outline: none!important;
}

body, html {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    color: $main_text;
    height: 100%;
}

/* body.noscroll {
    overflow: hidden;
} */

.bebas {
    font-family: $bebas_font;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */

    -webkit-appearance: none;
  -webkit-border-radius: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $bebas_font;
  font-style: normal;
  font-weight: normal;
}

h1, .h1 {
  font-size: 2.3em;
  line-height: 1.2;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 1.85em;
  line-height: 1;
  clear: left;

  @media (min-width: $sm) and (max-width: $md - 1px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 40px;
    margin-top: 70px;
  }

  @media (min-width: $md) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 40px;
    margin-top: 80px;
  }
}

h3, .h3 {
  font-size: 1.50em;
  line-height: 1.2;
  margin-top: 1.2em;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 1.5em;
}
p {
    margin: 0;
    margin-bottom: 1em;
}
.bold {
    font-weight: 700;
}
.bold-orange {
    font-weight: 700;
    color: $line_active_color;
    margin: 0;
    margin-bottom: 1em;
}
.orange-color {
    color: $line_active_color;
}

a {
    color: $link_color;
    text-decoration: none;
    cursor: pointer;
    &:hover, &:visited, &:active, &:focus {
        color: $main_text;
        text-decoration: none;
    }
}

.main-text-block {
  h2 {
    margin-bottom: 15px !important;
    margin-top: 20px !important;
  }
  .h2 {@extend h2;}

  @media (min-width: $md) {
    h2 {
        margin-bottom: 20px !important;
        margin-top: 30px !important;
    }
  }
}
span.top-block-list, .customers-switch {
    display: block;
    position: relative;
    color: $white_text;
    width: 280px;
    &:after {
        position: absolute;
        display: block;
        content: " ";
        width: 14px;
        height: 10px;
        background: url("../images/icon-arr-dropdown.png") no-repeat 100% 0px;
        top: 10px;
        right: 0;
        background-size: cover;
    }
    &.active {
          &:after {
            top: 7px;
            background: url("../images/icon-arr-dropdown.png") no-repeat 100% 100%;
          }
    }
/*     &.active {
    .bottom-line {
        opacity: 1;
    }
} */
}

.opened {
    span.top-block-list, .customers-switch {
        &:after {
            top: 7px;
            background: url("../images/icon-arr-dropdown.png") no-repeat 100% 100%;
        }
    }
}
/* .customers-switch {
    &:after {
        top: 15px;
        left: 267px;
    }
} */
.scroll-up {
    display: none;
    position: fixed;
    top: 80%;
    right: 0;
    margin-left: auto;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    z-index: 200;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    .page-up {
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        background: url(../images/scroll-up.png) 0 0 no-repeat;
        top: 0;
        cursor: pointer;
    }
}
.alt-li-type,
 {
    ul {
        padding: 0;
        li {
            padding-left: 18px;
            margin-bottom: 10px;
            position: relative;
            list-style-type: none;

            &:before {
                content: "";
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 9px;
                height: 2px;
                width: 10px;
                background-color: #e8771a;
            }
        }
    }

    &.doc_icon {
      ul {
        li {
          padding-left: 23px;
          display: inline-block;
          margin-right: 30px;

          &:before {
            //background: url(../images/license-icon.png) 0 0 no-repeat;
            background: url(../images/licon_doc.svg) 0 0 no-repeat;
            width: 16px;
            height: 22px;
            top: 0;
            left: 0px;
          }

          @media (min-width: $md) {
            font-size: 15px;
            padding-left: 30px;
            margin-bottom: 12px;
            margin-top: 12px;
            &:before {
              width: 22px;
                height: 31px;
                background-size: contain;
                top: -5px;
              background-size: contain;
            }
          }
        }
      }
    }

    &.doc_icon-big {
      ul {
        //padding-left: 20px;
        margin-left: -20px;
        li {
          padding-left: 0;
          display: block;
          margin: 0 0 30px 20px;

          a, a:visited {
            display: block;
            text-decoration: none;
            background: rgb(48,48,48);
            padding: 15px 20px 15px 90px;
            height: 100px;
            width: 100%;
            position: relative;
            font-family: $bebas_font;
            font-size: 16px;
            line-height: 20px;
            color: #fff;

            svg {
                width: 50px;
                height: 68px;
                position: absolute;
                left: 20px;
                top: -15px;
            }

            span {
                display: block;
                font-family: "Open Sans", sans-serif;
                font-size: 12px;
                color: #fff;
                margin-top: 4px;
            }

            &:before {
                content: "";
                position: absolute;
                //background: url(../images/license-icon.png) 0 0 no-repeat;
                background: url(../images/icon-doc.svg) 100% 0 no-repeat;
                background-size: auto 100%;
                 width: 50px;
                height: 68px;
                left: 20px;
                top: -15px;
                z-index: 1;
                display: none;
            }

            &:hover,
            &:active {
                /* background: $line_active_color;
                svg {
                    line, polyline {
                        fill: #fff;
                    }
                    
                } */
                &:before {
                    //background-position: 0 0;
                }
            }
          }
        }
      }

      @media (min-width: $sm) {
        ul {
            padding-left: 10px;
            padding-top: 15px;
            clear: both;
            margin: 0 0 0 -10px;
            //overflow: hidden;
            li {
                width: 260px;
                float: left;
                margin: 0 10px 30px;
            }
        }
      }

      @media (min-width: $md) {
        ul {
            padding-left: 0px;
            padding-top: 15px;
            clear: both;
            margin: 0 0 50px 0px;
            //overflow: hidden;
            li {
                width: 306px;
                float: left;
                margin: 0 20px 30px 0;

                a {
                    padding: 15px 20px 15px 95px;
                    svg {
                        width: 60px;
                        height: 86px;
                    }
                }
            }
        }
      }
    }
}
li span {
    font-weight: 400;
    color:  $main_text; /* Цвет текста в списке */
}
.content-block {
    position: relative;
    color: $main_text;
    @media (max-width: $sm - 1px) {
        .col-xs-padding {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .alpinist-block {
        display: block;
        overflow: hidden;
        min-height: 100%;
        .alpinist-4k-block {
            height: 2230px;
            background: url(../images/high-alpinist-4k.png) 0 -115px no-repeat;
            background-size: cover;
            transform: scale(-1, 1);
            margin-right: 108px;
        }
        @media (max-width: $lg - 1px) {
            .alpinist-4k-block {
                margin-right: 32px;
            }
        }
    }
    .content-block-wrapper {
        position: relative;
        padding-left: 54px;
        padding-right: 74px;
        padding-top: 10px;
        padding-bottom: 40px;
        width: 100%;
        @media (max-width: $lg - 1px) {
            font-size: 0.92em;
            padding-left: 30px;
            padding-right: 0;
        }
        @media (max-width: $md - 1px) {
            padding-right: 0;
        }
        @media (max-width: $sm - 1px) {
            padding-left: 0;
            padding-top: 0;
            width: 303px;
            margin-left: -15px;
        }
        p {
            margin-top: 30px;
        }
        .wrapper-II {
            @media (max-width: $md - 1px) {
                padding-left: 15px;
                .title-block {
                    h1 {
                        font-size: 2em;
                    }
                    h2 {
                        font-size: 1.57em;
                        line-height: 1.18;
                    }
                }
            }
            @media (max-width: $sm - 1px) {
                .title-block {
                    h2 {
                        font-size: 1.29em;
                        line-height: 1.3;
                    }
                }
            }
            .text-block-usluga {
                margin-top: 10px;
                @media (max-width: $sm - 1px) {
                    width: 100%;
                }
            }
            .block-list {
                li {
                    font-weight: 700;
                    color: $line_active_color; /* Цвет маркеров */
                    padding-left: 6px;
                    margin-bottom: 10px;
                    list-style: decimal inside;
                }
                @media (max-width: $sm - 1px) {
                    margin-top: 30px;
                }
            }
            .block-stage {
                @media (max-width: $sm - 1px) {
                    margin-top: 30px;
                }
            }
            .jobs-block {
                margin-top: 80px;
                @media (max-width: $md - 1px) {
                    margin-top: 70px;
                }
            }
            .jobs-block-video {
                margin-top: 65px;
                @media (max-width: $md - 1px) {
                    margin-top: 60px;

                }
                @media (max-width: $sm - 1px) {
                    margin-top: 50px;
                    width: 248px;
                }
            }
            .breadcrumb {
                margin-top: 80px;
                font-size: 14px;
                padding-bottom: 0;
                color: $line_hover_color;
                .this {
                    color: $main_text;
                }
                .slash {
                    padding: 0 4px;
                    color: $line_active_color;
                }
            }
        }
        .wrapper-II-alt {
            width: 820px;
            @media (max-width: $md - 1px) {
                width: 100%;
                padding: 0;
            }
            @media (max-width: $sm - 1px) {
                width: 100%;
                padding-left: 20px;
            }
            .content-table-block-wrapper {
                overflow: auto;
            }
            .content-table-block {
                min-width: 820px;
                margin-top: 30px;
                @media (max-width: $md - 1px) and (min-width: $sm - 1px) {
                    min-width: 700px;
                    margin-top: 30px;
                }
                tr {
                    border: $table_in_border_color;
                    border-top: none;
                }
                td, th {
                    width: 142px;
                    font-size: 1.14em;
                    font-weight: 300;
                    padding: 20px 0 20px 20px;
                    text-align: left;
                }
                th {
                    color: $white_text;
                    background-color: $main_text;
                    border: 1px solid $main_text;
                }
                th:first-child {
                    font-size: 1em;
                    width: 252px;
                }
                td:first-child {
                    background-color: $line_color;
                    font-size: 1em;
                }
                @media (max-width: $lg - 1px) {
                    td, th {
                        font-size: 1.07em;
                    }
                    th:first-child {
                        font-size: 0.93em;
                    }
                    td:first-child {
                        font-size: 0.93em;
                    }
                }
                @media (max-width: $md - 1px) {
                    td, th {
                        font-size: 1.07em;
                    }
                    th:first-child {
                        font-size: 0.93em;
                    }
                    td:first-child {
                        font-size: 0.93em;
                    }
                }
                @media (max-width: $sm - 1px) {
                    td, th {
                        font-size: 1.07em;
                    }
                    th:first-child {
                        font-size: 0.93em;
                    }
                    td:first-child {
                        font-size: 0.93em;
                    }
                }
            }
        }
        .jobs-block-slider {
            position: relative;
            width: 715px;
            height: 394px;
            margin-top: 34px;
            background: url(../images/antenna-1.png) 0 -168px no-repeat;
            border-bottom: 3px solid $line_hover_color;
            .slider-button {
                display: block;
                position: absolute;
                width: 40px;
                height: 50px;
                background: url(../images/slider-button.png) 0 0 no-repeat;
                top: 173px;
                right: 675px;
            }
            .slider-button-left {
                right: 675px;
                transform: scale(-1, 1);
            }
            .slider-button-right {
                right: 0;
            }
            .slider-indicator {
                display: block;
                position: absolute;
                width: 105px;
                height: 6px;
                background-color: $line_active_color;
                bottom: -3px;
                left: 0;
            }
            @media (max-width: $md - 1px) {
                width: 510px;
                height: 283px;
                padding-left: -15px;
                padding-right: -15px;
                margin-top: 25px;
                .slider-button {
                    top: 115px;
                }
                .slider-indicator {
                    width: 105px;
                }
                .slider-button-left {
                    right: 470px;
                }
                .slider-button-right {
                    right: 0;
                }
            }
            @media (max-width: $sm - 1px) {
                width: 300px;
                height: 250px;
                padding-left: -15px;
                padding-right: -15px;
                margin-top: 25px;
                .slider-button {
                    top: 98px;
                }
                .slider-indicator {
                    width: 65px;
                }
                .slider-button-left {
                    right: 260px;
                }
                .slider-button-right {
                    right: 0;
                }
            }
        }
        .jobs-block-video {
            position: relative;
            width: 715px;
            height: 397px;
            margin-top: 22px;
            background: url(../images/antenna-1.png) -600px -100px no-repeat;
            @media (max-width: $md - 1px) {
                margin-top: 28px;
                width: 510px;
                height: 283px;
            }
            @media (max-width: $sm - 1px) {
                margin-top: 28px;
                width: 300px;
                height: 250px;
            }
            &:before {
                content: " ";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: $main_text;
                opacity: 0.7;
            }
            .jobs-block-video-button {
                .jobs-block-video-button-link {
                    position: absolute;
                    display: block;
                    width: 100px;
                    height: 100px;
                    background: url(../images/video-button.png) 0 0 no-repeat;
                    top: 50%;
                    left: 50%;
                    margin-left:-50px;
                    margin-top:-50px;
                }
                .jobs-block-video-button-link:hover {
                    background: url(../images/video-button.png) 0 -100px no-repeat;
                }
                @media (max-width: $sm - 1px) {
                    .jobs-block-video-button-link {
                        background: url(../images/video-button-60.png) 0 0 no-repeat;
                        width: 60px;
                        height: 60px;
                        margin-left:-30px;
                        margin-top:-30px;
                    }
                    .jobs-block-video-button-link:hover {
                        background: url(../images/video-button-60.png) 0 -60px no-repeat;
                    }
                }
            }
        }
    }
}

.fancybox-close {
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    background: $button_close_color;
    &:before {
        content: " ";
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        top: 18px;
        right: 18px;
        background: url(../images/cross-close.png) 0 0 no-repeat;
    }
    &:hover {
        background: $line_hover_color;
    }
}
.webform-client-form {
    display: block;
    position: relative;
    //width: 400px;
    padding: 0;
    .webform-component {
        &.error-div {
            input[placeholder] {color:red;}
            input.error, textarea.error {
                border: none;
                border-bottom:2px solid red;
                color: red;

                ::-webkit-input-placeholder {color:red;}
                ::-moz-placeholder         {color:red;}/* Firefox 19+ */
                :-moz-placeholder       {color:red;}/* Firefox 18- */
                :-ms-input-placeholder     {color:red;}
            }
        }
        input, textarea {
            width: 100%;
            padding: 25px 0 10px;
            border: none;
            font-family: 'Open Sans', sans-serif;
            color: $line_hover_color;
            cursor: pointer;
            border-bottom: $form_border;
            &:hover {
                color: $main_text;
                border-bottom: $form_border_hover;
            }
        }
        textarea {
            height:54px;
        }
        h2 {
            margin-top: 36px;
        }
    }
    .form-actions {
        margin: 0;
        .form-button {
            padding: 0;
            margin-top: 15px;
            width: 100%;
            height: 60px;
            border: 1px solid $line_hover_color;
            background-color: transparent;
            font-family: 'Open Sans', sans-serif;
            &:active,
            &:hover {
                border-color: $line_active_color;
            }
        }
    }
    .user-agreement {
        margin-top: 10px;
        color: $form_text_color;
        text-align: center;
        a {
            color: $line_active_color;
            text-decoration: none;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8US5VDwAFwgH62SBgegAAAABJRU5ErkJggg==);
            background-repeat: repeat-x;
            background-position: center 1.25em;
        }
        @media (max-width: $md) {
          font-size: 13px;
          text-align: left;
        }
    }
    @media (max-width: $sm - 1px) {
        h1 {
            font-size: 30px;
        }
    }
}

.fancybox-type-html {
    .webform-client-form {
        width: 400px;
        padding: 0 65px 50px 65px;
        @media (max-width: $sm - 1px) {
            width: 280px;
            padding: 0 15px 50px 15px;
        }
    }
}

.block-webform {
    .messages {
        display: none;
    }
}

.not-front {
    #footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

#footer {
    
    background: $main_text;
    font-size: 12px;
    font-weight: 300;
    color: $white_text;
    height: 70px  !important;
    border-top: 1px solid #333;
    
        @media (max-width: $lg - 1px) {
            height: 70px !important;
        }
        @media (max-width: $md - 1px) {
            height: 170px !important;
            font-size: 14px;
        }
        @media (max-width: $sm - 1px) {
            height: 240px !important;
        }
    @media (min-width: $md) {
        .container {
            width: 100% !important;
            max-width: 1350px;
        }
    }
    .block {
        margin-bottom:0;

        &.adres {
            margin-bottom: 10px;
        }

        &.agreement {
            margin-bottom: 20px;
        }
        &.phone-block {
            margin-left: 25px;
        }

        @media (min-width: $md) {
            display: inline-block;
            margin-bottom: 4px !important;
            &.agreement {
                margin-left: 25px;
            }

            &.dev {
                display: block !important;
                margin-left: 0 !important;
            }

            &.social-icons {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                width: 71px;
            }

            &.phone-block {
                position: absolute;
                right: 200px;
                top: 0;
                padding: 0;
                width: 95px;
                padding: 0 !important;
            }

            &.callback-block {
                position: absolute;
                right: 300px;
                top: 0;
                padding: 0;
                //width: 95px;
            }

            &.order-link {
                position: absolute;
                right: 95px;
                top: 0;
                padding: 0;
                width: 90px;
                left: initial;

               
            }
        }
    }
    p {
        margin-bottom:0;
    }
    a {
        color: $white_text;
    }
    padding: 0;
    .footer-block {
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;

        a,
        a:visited {
            color: rgb(146,146,146);
            text-decoration: none;
            /*
            background: -moz-linear-gradient(left, rgb(146,146,146), rgb(146,146,146) 100%);
            background: -ms-linear-gradient(left, rgb(146,146,146), rgb(146,146,146) 100%);
            background: -o-linear-gradient(left, rgb(146,146,146), rgb(146,146,146) 100%);
            background: -webkit-gradient(linear, 0 0, 100% 0, from(rgb(146,146,146)), to(rgb(146,146,146)));
            background: -webkit-linear-gradient(left, rgb(146,146,146), rgb(146,146,146) 100%);
            background: linear-gradient(left, rgb(146,146,146), rgb(146,146,146) 100%);
            background-position: 0 100%;
            background-size: 10px 1px;
            background-repeat: repeat-x;
            */
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOcVA8AAakBE6hGgM8AAAAASUVORK5CYII=);
            background-repeat: repeat-x;
            background-position: center 1.25em;

            &:hover,
            &:active {
                color: #e8771a;
                text-decoration: none;
                /*
                background: -moz-linear-gradient(left, #e8771a, #e8771a 100%);
                background: -ms-linear-gradient(left, #e8771a, #e8771a 100%);
                background: -o-linear-gradient(left, #e8771a, #e8771a 100%);
                background: -webkit-gradient(linear, 0 0, 100% 0, from(#e8771a), to(#e8771a));
                background: -webkit-linear-gradient(left, #e8771a, #e8771a 100%);
                background: linear-gradient(left, #e8771a, #e8771a 100%);
                background-position: 0 100%;
                background-size: 10px 1px;
                background-repeat: repeat-x;
                */

                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8US5VDwAFwgH62SBgegAAAABJRU5ErkJggg==);
                background-repeat: repeat-x;
                background-position: center 1.25em;
            }
        }

        @media (max-width: $lg - 1px) {
            padding-top: 15px;
            padding-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
        @media (max-width: $md - 1px) {
            padding-top: 36px;
        }
        @media (max-width: $sm - 1px) {
            padding-top: 20px;
        }
        .wide {
            padding-left: 0;
            padding-right: 0;
            @media (max-width: $md - 1px) {
                text-align: center;
            }
            @media (max-width: $sm - 1px) {
                font-size: 0.93em;
            }
        }
        .left-off {
            padding: 0 80px 0 0;
            text-align: right;
            @media (max-width: $md - 1px) {
              padding: 0;
              text-align: left;
            }
        }
        .region-footer-left {
            .dev {
                color: $white_text;
                @media (max-width: $md - 1px) {
                    color: $footer_text_color;
                }
            }
            .dev {
                vertical-align: top;
                display: inline-block;
                position: relative;
                padding-left: 25px;
                padding-right: 0;
                margin-left: 25px;
                opacity: 0.6;
                white-space: nowrap;
                .content {
                    color: rgb(146,146,146);
                     a {
                        background: none;
                     }
                }

                &:after {
                    position: absolute;
                    display: block;
                    content: " ";
                    color: transparent;
                    text-indent: -9999px;
                    width: 22px;
                    height: 19px;
                    //background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAATCAYAAACUef2IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfJJREFUeNqsVLFKA0EQnd27SBAsFLGwSoytvSAWoqCFvyLYWlja2uUb7E0hRkQr8Q9ERE0UkaAYKyN4t+vM3ay8nFFEHXjs3ezs25l3M2cI7JJompdzhlVXyojoe/MMU3i/tkoYDs8Dof8BKQGp19Uxjiy8iK1qYASB9IOM0eTsvgEZhqQExiQesKOjWCa552fe8d9J8cqoxOCYUVKvyKqpPD2R63bziFKJDONld5ce19Yo7XQGXXBWI+pY2Fj4SrfW2FiOkRG6rdXI9Xo0sbMzKFa+zeGH8zJfD5TcQLCb8t5emT41RJ6skptKhZJ2uyjFIuM4SFFmzGr5DjkGtkG5nD8kCRVIe4wTlsKFg3OMYQlVMqNlUV/XWOviapXG6/W012wmyd0d3ifxp0z6KgrEkL6QxUoeK7IBETlyemeS+/v0pdEwDxsbke5baNE9IWVyH4iXC32IU+dZYwd7uB+kcHrBYU0/uOEbxnntQFDfeHK2QmyAQAhLhXEXPzd4xmWCxkvQt2+FbIhJLYy4BbLwoYO/KU4hDRovQHBJgyPQ2unqoeSgrQX/kbauDRmvfDH3MXTJm0rhYcKCNOF5DzKOCQYku03XFiNleMY6/E/w32LgXFTc/2RCrsRy8yb91ZQwZHzB2P4X0oIkW+j/jb0LMAB87r1+A+cLEAAAAABJRU5ErkJggg==");
                    background-image: url("../images/icon-dev.svg");
                    background-repeat: no-repeat;
                    top: 5px;
                    left: 0;
                    opacity: 0.8;
                }
                .site-manufacturer-service {
                    .site-manufacturer-name{
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOcVA8AAakBE6hGgM8AAAAASUVORK5CYII=);
                        background-repeat: repeat-x;
                        background-position: center 1.25em;
                    }
                    &:hover {
                        text-decoration: none;

                        .site-manufacture-name {
                            color: #e8771a;
                            text-decoration: none;
                            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8US5VDwAFwgH62SBgegAAAABJRU5ErkJggg==);
                            background-repeat: repeat-x;
                            background-position: center 1.25em;
                        }
                    }
                    .site-manufacturer-link {
                        // color: $white_text;
                        text-decoration: none;
                    }
                }
                @media (max-width: $md - 1px) {
                    position: absolute;
                    top: 86px;
                    right: 0;
                    margin: 0;
                }
                @media (max-width: $sm - 1px) {
                        position: absolute;
                    /* top: 135px; */
                    left: 15px;
                    /* margin-top: 20px; */
                    /* margin-bottom: 20px; */
                    width: 221px;
                    margin: 0;
                    bottom: -75px;
                    top: initial;
                }
            }

            @media (min-width: $md) {
                width: 100% !important;
                position: relative;
            }
        }
        .region-footer-midlle {
            display: inline-block;
            padding-left: 40px;
            @media (max-width: $lg - 1px) {
                text-align: center;
                padding-left: 80px;
            }
            @media (max-width: $md - 1px) {
                padding-left: 0;
            }
            .adres {
                display: inline-block;
                vertical-align: top;
                padding-right: 0;
                .adres-item {
                    position: relative;
                }
                @media (max-width: $lg - 1px) {
                    text-align: center;
                    padding-left: 0;
                }
                @media (max-width: $sm - 1px) {
                    display: block;
                    position: static;
                    text-align: left;
                    /* margin-right: -20px; */
                }
            }
            .short-adres {
                display: inline-block;
                position: relative;
                vertical-align: top;
                padding-right: 0;
                @media (max-width: $sm - 1px) {
                    padding-top: 4px;
                }
            }
        }
        .city-changer {
            display: inline-block;
            position: relative;
            vertical-align: top;
            padding-left: 19px;
            padding-right: 0;
            &:before {
                content: "г.";
                display: block;
                position: absolute;
                left: 6px;
            }
            @media (max-width: $md - 1px) {
                padding-left: 22px;
            }
            @media (max-width: $sm - 1px) {
                vertical-align: middle;
                padding-top: 38px;
                padding-left: 20px;
            }
            .unselected-city {
                display: none;
            }
        }
        .phone-block {
            display: inline-block;
            padding-left: 185px;

            .content {
                a {
                    background: none;
                    color: #fff;

                    .tel-code {
                        color: rgb(146,146,146);
                    }
                }
            }
            @media (max-width: $lg - 1px) {
                padding-left: 4px;
            }
            @media (max-width: $md - 1px) {
                position: absolute;
                bottom: -32px;
                position: absolute;
                left: 16px;
                font-size: 1.2em;
            }
            @media (max-width: $sm - 1px) {
                position: static;
/*                 top: 54px;
left: -20px;
padding: 0;
font-size: 1.2em; */
margin-top: 5px;

                    top: initial;
    left: initial;
    padding: 0;
    font-size: 1.2em;
            }
        }
        .region-footer-right {
            text-align: left;
            display: inline-block;
            @media (max-width: $sm - 1px) {
                position: relative;
                left: 0;
            }
            .agreement {
                display: block;
                position: absolute;
                top: 0;
                left: -80px;
                vertical-align: top;
                text-align: center;
                padding-left: 0;
                @media (max-width: $lg - 1px) {
                    padding-left: 0;
                    margin-top: 12px;
                    top: 15px;
                    left: -360px;
                }
                @media (max-width: $md - 1px) {
                    position: relative;
                    top: 0;
                    left: 0;
                    padding-left: 0;
                }
                @media (max-width: $sm - 1px) {
                    position: relative;
                    padding-left: 0;
                    text-align: left;
                }
            }
            .phone {
                position: relative;
                display: inline-block;
                vertical-align: top;
                padding-left: 85px;
                @media (max-width: $lg - 1px) {
                    padding-left: 0;
                }
            }
        }
    }
}
.social-icons {
    display: inline-block;
    padding-left: 15px;
    padding-right: 0;
    vertical-align: top;
    @media (max-width: $md - 1px) {
        position: absolute;
        top: 0;
        right: 0;
        width: 90px;
        height: 24px;
    }
    @media (max-width: $sm - 1px) {
        position: absolute;
    bottom: 0;
    right: 0;
    top: initial;
    left: initial;
    }
    .social-icon-1 {
        display: block;
        &:after {
            background: url(../images/social-icons.png) 0 0 no-repeat;
            left: 0px;
        }
        &:hover {
            &:after {
                background: url(../images/social-icons.png) 0 -21px no-repeat;
            }
        }
    }
    .social-icon-2 {
        display: block;
        height: 20px;
        &:after {
            background: url(../images/social-icons.png) 0 -43px no-repeat;
            left: 41px;
        }
        &:hover {
            &:after {
                background: url(../images/social-icons.png) 0 -62px no-repeat;
            }
        }
    }

    @media (max-width: $md - 1px) {
        .social-icon-1 {
            &:after {
                background-position: 0 0;
            }
            &:hover {
                &:after {
                    background-position: 0 -25px;
                    background-size: 105%;
                }
            }
        }
        .social-icon-2 {
            &:after {
                background-position: 0 -50px;
                background-size: 105%;
            }
            &:hover {
                &:after {
                    background-position: 0 -73px;
                    background-size: 105%;
                }
            }
        }
    }
    .social {
        position: relative;
        &:after {
            content: " ";
            position: absolute;
            display: block;
            width: 31px;
            height: 21px;
            top: 0px;
            @media (max-width: $md - 1px) {
                width: 35px;
                height: 25px;
                background-size: 105%;
            }
        }
    }
}
.seo-text__front {
    .content {
            padding-bottom: 72px;

        @media (max-width: $lg - 1px) {
            padding-bottom: 72px;
        }
        @media (max-width: $md - 1px) {
            padding-bottom: 172px;

        }
        @media (max-width: $sm - 1px) {
            padding-bottom: 240px;
        }
    }
}
.show-full-link {
    cursor:pointer;
    line-height: 1;
}
.webform-confirmation {
    padding: 0 20px;
}
.layer-wrapper {
    position: fixed;
    display: none;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgb(37,37,37);
    z-index: 900;

    @media (max-width: $md - 1px) {
        overflow-y: auto;
        /* .container {
            max-height: 100%;
            overflow-y: scroll;
        } */
    }
 }
.menu-main-wrapper {
    display: block;
    top: 0;
    left: 0;
}
.menu-row {
    position: relative;
    .menu-col {
        position: relative;
        .logo-link {
            @media (max-width: $md - 1px) {
                margin-left: 30px;
            }
        }
        .menu-ref {
            position: absolute;
            width: 120px;
            height: 100px;

            .menu-logo {
                display: block;
                width: 100%;
                height: auto;
                /* top: 50%;
                left: 50%;
                margin-left:-40px;
                margin-top:-35px;
                //background: url("../images/logo_color.svg") no-repeat 0 0;
                background-size: contain; */


            }
            @media (max-width: $lg - 1px) {
                width: 100px;
                height: 80px;
                // .menu-logo {
                //     width: 60%;
                //     height: auto;
                //     margin-left: -30px;
                //     margin-top: -30px;
                // }
            }
            @media (max-width: $md - 1px) {
                top: 0;
                width: 60px;
                height: 60px;
                &.logo-link {
                    height: 50px;
                    margin-left: 13px;
                    top: 5px;
                    //background: url("../images/logo_color.svg") no-repeat 0 0;
                    background-size: contain;
                }
            }

            @media (min-width: $sm) {
                &.logo-link {
                    margin-left: 30px;
                }
            }

            @media (min-width: $md) {

                &.logo-link {
                    width: 100px;
                    height: 80px;
                    margin: 0;
                        padding: 12px 20px;
                        background: #3b3b3b;
                        /* &:before {
                        content: "";
                        position: absolute;
                        height: 50px;
                        width: 60px;
                        margin-left: 23px;
                        top: 13px;
                        //background: url("../images/logo_color.svg") no-repeat 0 0;
                        background-size: contain;
                                            } */
                }
            }

            @media (min-width: $lg) {

                &.logo-link {
                    width: 120px;
                    height: 100px;
                    margin: 0;
                        &:before {
                        content: "";
                        position: absolute;
                        height: 80px;
                        width: 70px;
                        margin-left: 26px;
                        top: 18px;
                        //background: url("../images/logo_color.svg") no-repeat 0 0;
                        background-size: contain;
                    }
                }
            }

        }
        .menu-close {
            display: inline-block;
            position: relative;
            width: 110px;
            cursor: pointer;
            background: #3b3b3b;
            @media (max-width: $lg - 1px) {
                width: 80px;
            }
            @media (max-width: $md - 1px) {
                width: 60px;
                background-color: $gray_fon;
            }
            &:before {
                content: " ";
                display: block;
                position: absolute;
                width: 22px;
                height: 22px;
                top: 50%;
                left: 50%;
                margin-left:-11px;
                margin-top:-11px;
                background: url(../images/cross-close.png) 0 0 no-repeat;
            }
            &:hover {
                &:before {
                    opacity: 0.6;
                }
            }
        }
        .top-vector-wrapper {
            position: relative;
            div {
                word-spacing: 1000px;
            }
            a {
                color: $white_text;
                word-spacing: normal;
                white-space: nowrap;
            }
            @media (max-width: $sm - 1px) {
                display: block;
                margin-top: 85px;
            }
        }
        .bottom-vector-wrapper {
            word-spacing: 1000px;
            a {
                word-spacing: normal;
                white-space: nowrap;
            }
            @media (max-width: $md - 1px) {
                width: 84%;
                margin-bottom: 10px;
            }
        }
        .vector-menu {
            font-size:3.85em;
            line-height: 0.77;
            color: $white_text;
            display: inline-block;
            padding: 0 0 14px 14px;
            margin-bottom: 60px;
            border-bottom: $form_border_hover;
            border-left: $form_border_hover;
            &:hover {
                border-color: $line_hover_color;
            }
            &:active  {
                border-color: $line_active_color;
            }
            @media (max-width: $lg - 1px) {
                font-size:3.14em;
                margin-bottom: 40px;
            }
            @media (max-width: $md - 1px) {
                font-size:1.71em;
                color: $line_hover_color;
            }
            @media (max-width: $sm - 1px) {
                color: $line_hover_color;
                display: inline-block;
            }
        }
        .main-menu {
            vertical-align: middle;
            margin-left: 330px;
            @media (max-width: $lg - 1px) {
                margin-left: 224px;
            }
            @media (max-width: $md - 1px) {
                margin-left: 0;
                margin-right: 66px;
                padding-left: 30px;
            }
            @media (max-width: $sm - 1px) {
                margin-left: 0;
                padding-left: 0;
            }

            @media (min-width: $sm) {
                padding-left: 10px;
            }
        }
    }
    .menu-col-left {
        text-align: left;
    }
    .menu-col-right {
        text-align: right;
    }
    .menu-row-wrapper {
        padding: 60px 0;
        overflow: hidden;
        clear: both;

        @media (min-width: $md) {
            padding: 90px 0;
        }
    }
}
.request {
    display: block;
    position: relative;
    margin-top: 5px;
    padding: 0 0 12px 0;
    width: 77%;
    input[placeholder] {color:$button_close_color;}
    ::-webkit-input-placeholder {color:$button_close_color;}
    ::-moz-placeholder          {color:$button_close_color;}/* Firefox 19+ */
    :-moz-placeholder           {color:$button_close_color;}/* Firefox 18- */
    :-ms-input-placeholder      {color:$button_close_color;}
    border-bottom: $form_border_hover;
    .request-text {
        font-family: inherit;
        line-height: inherit;
        color: $button_close_color;
        background-color: transparent;
        border: 0;
        padding: 0;
    }
    .request-icon {
        position: absolute;
        display: block;
        width: 14px;
        height: 15px;
        background: url(../images/request-icon.png) 0 0 no-repeat;
        top: 5px;
        right: 5px;
        &:active  {
            border-color: $line_active_color;
        }
    }
    &:hover {
        border-color: $line_hover_color;
    }
    @media (max-width: $lg - 1px) {
        margin-top: 25px;
        width: 70%;
    }
    @media (max-width: $md - 1px) {
        margin-top: 5px;
        display: block;
        width: 92%;
        .request-icon {
            width: 20px;
            height: 20px;
            background: url(../images/request-icon-lg.png) 0 0 no-repeat;
            top: 0;
            right: 0;
        }
    }
    @media (max-width: $sm - 1px) {
        position: absolute;
        top: 0px;
        left: 15px;
        margin-top: 0px;
        width: 90%;
        .request-icon {
            width: 20px;
            height: 20px;
            background: url(../images/request-icon-lg.png) 0 0 no-repeat;
            top: 0;
            right: 0;
        }
    }
}

.company-wrapper {
    display: none;
    margin-bottom: 80px;
    padding-top: 100px;
    .second-container {
        padding-left: 20px;
    }
    .main-text-block {
        padding-top: 30px;
        width: 755px;
        p {
            margin-bottom: 1.5em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .block-title h1 {
        line-height: 1.0;
    }
    .company-container {
        width: 1350px;
        position: relative;
        height: 240px;
        padding-left: 0;
        padding-right: 0;
        color: #fff;
        background: url(../images/city.png) -85px 20px no-repeat #252525;
        overflow: hidden;
        background-size: auto 90%;
        .top-block-wrapper {
            height: 100%;
            margin-left: 40px;
            .items-text-wrapper {
                margin-top: 30px;
                .section-text {
                    display: inline-block;
                        p {
                            margin-bottom: 5px;
                        }
                    .sub-title, .digits {
                        font-size: 1.57em;
                    }
                }
                .divide {
                    display: inline-block;
                    width: 90px;
                    margin: 0 30px;
                    margin-bottom: 37px;
                    border-top: 2px solid $line_hover_color;
                }
            }
        }
        @media (max-width: $lg - 1px) {
            width: 1024px;
        }
        @media (max-width: $md - 1px) {
            width: 640px;
        }
        @media (max-width: $sm - 1px) {
            width: 320px;
        }
    }
    .team-block-wrapper {
        display: inline-block;
        width: 900px;
        margin-top: 50px;
        .city-title {
            display: block;
            font-size: 1.71em;
            line-height: 1.0;
        }
        .team-block {
            display: inline-block;
            margin-top: 30px;
            text-align: left;
            .expert-card {
                display: inline-block;
                float: left;
                width: 200px;
                text-align: center;
                margin-right: 20px;
                .expert-foto {
                    display: inline-block;
                    width: 190px;
                    height: 220px;
                    overflow: hidden;
                    text-align: center;
                }
                .expert-foto img {
                    height: 100%;
                }
                .expert-name {
                    display: block;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    margin-top: -5px;
                    padding-left: 10px;
                    text-align: left;
                    font-size: 1.28em;
                    color: $white_text;
                    background-color: $line_active_color;
                }
                .expert-post {
                    display: block;
                    height: 100px;
                    padding-top: 10px;
                    padding-left: 10px;
                    text-align: left;
                    font-size: 0.85em;
                    color: $main_text;
                }
            }
        }
    }
    .safety-wrapper {
        .safety-title {
            font-size: 2.14em;
        }
    }
}
.company-wrapper {
    @media (max-width: $lg - 1px) and (min-width: $md) {
        padding-top: 80px;
        .block-title h1 {
            font-size: 2.14em;
            padding-top: 50px;
        }
        .company-container {
            .top-block-wrapper {
                margin-left: 30px;
            }
        }
        .second-container {
            padding-left: 30px;
        }
        .main-block-wrapper {
            margin-left: 0;
        }
        .main-text-block {
            width: 740px;
            font-size: 0.93em;
        }
        .team-block-wrapper {
            margin-top: 50px;
            .block-title h1 {
                font-size: 2.0em;
            }
            .team-block {
                .expert-card {
                    .expert-post {
                    height: 90px;
                    }
                }
            }
        }
        .city-block-wrapper{
            .city-title {
                font-size: 1.57em;
            }
        }
        .safety-wrapper {
            .safety-title {
                font-size: 2.0em;
            }
        }
    }
}
.company-wrapper {
    @media (max-width: $md - 1px) {
        padding-top: 62px;
        .block-title h1 {
            font-size: 2.14em;
        }
        .border-left {
            padding-left: 0;
            border-left: 0;
        }
        .company-container {
            height: 500px;
            background-position: -288px 250px;
            background-size: auto 50%;
            .top-block-wrapper {
                margin-left: 50px;
                .items-text-wrapper {
                    margin-top: 40px;
                    .section-text {
                        display: block;
                        .item-text {
                            font-size: 1.14em;
                        }
                    }
                    .divide {
                        display: block;
                        border: none;
                        border-left: 2px solid $line_hover_color;
                        height: 50px;
                        margin: 10px 0;
                        margin-left: 35px;
                    }
                }
            }
        }
        .second-container {
            padding-left: 50px;
        }
        .main-text-block {
            width: 530px;
        }
        .team-block-wrapper {
            width: 510px;
            margin-top: 30px;
            .team-block {
                .expert-card {
                    .expert-post {
                    height: 95px;
                    }
                }
            }
        }
        .city-block-wrapper{
            .city-title {
                font-size: 1.57em;
            }
        }
        .safety-wrapper {
            .safety-title {
                font-size: 2.0em;
            }
        }
    .license-button:before{
        top: 17px;
        left: 82px;
        }
    }
}
.company-wrapper {
    @media (max-width: $sm - 1px) {
        .company-container {
            .top-block-wrapper {
                margin-left: 20px;
            }
        }
        .second-container {
            padding: 0 10px;
        }
        .main-text-block {
            width: 280px;
        }
        .team-block-wrapper {
            width: 280px;
            .block-title h1 {
                font-size: 2.0em;
            }
            .team-block {
                .expert-card {
                    .expert-post {
                    height: 75px;
                    }
                }
            }
        }
    }
}

.portfolio-wrapper {
    display: none;
    margin-left: -15px;
    margin-right: -15px;
    background-color: $main_text;
    @media (max-width: $sm - 1px) {
        margin-left: -20px;
        margin-right: -20px;
        .portfolio-container {
            width: 320px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width: $sm) {
        margin-left: -80px;
        margin-right: -60px;
    }

    @media (min-width: $sm) and (max-width: $md - 1px) {
        .container {
            width: 100%;
        }
    }

    @media (min-width: $md) {
         margin-left: -40px;
        margin-right: -40px;
    }

    @media (min-width: $lg) {
        margin-left: -55px;
    }
}

/* #block-custom-portfolio {
    @media (max-width: $sm - 1px) {
        width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
} */
.portfolio-col {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    //color: $white_text;
    .portfolio-up-block {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 60px;
        @media (max-width: $lg - 1px) {
            margin-top: 0;
            margin-left: 0;
            margin-bottom: 50px;
        }
        @media (max-width: $md - 1px) {
            margin-top: 0;
            margin-left: 15px;
            margin-bottom: 70px;
        }
        @media (max-width: $sm - 1px) {
            margin-left: 0px;
            margin-bottom: 50px;
        }
        .portfolio-title, .portfolio-orange-title {
            display: block;
            font-size: 2.14em;
            line-height: 1.2;
            font-family: $bebas_font;
            color: #fff;
            @media (max-width: $md - 1px) {
                font-size: 1.43em;
            }
        }
        .portfolio-orange-title {
            color: $line_active_color;
        }
        .main-button {
            border: 1px solid rgb(168,168,168);
            color: $line_active_color !important;

            &:hover,
            &:focus {
                border-color: $line_active_color;
            }
        }
        .customers-switch {
            cursor:pointer;
            color: $white_text;
            margin-top: 50px;
            font-size: 1.43em;
            @media (max-width: $md - 1px) {
                margin-top: 40px;
            }
            @media (max-width: $sm - 1px) {
                margin-top: 20px;
            }
        }
        .customers-logo-wrapper {
            text-align: left;
           /*  width: 93%; */
            margin-top: 40px;
            overflow: hidden;
            height: auto;
            .customers-logo {
                display: inline-block;
                position: relative;
                width: 120px;
                margin-bottom: 10px;
                margin-right: 5px;
                text-align: center;
                vertical-align: top;
                cursor: pointer;

                @media (min-width: $sm) {
                    margin-right: 10px;
                }

                img {
                    opacity: 0.5;
                }

                &:hover,
                &:focus,
                &:active {
                    img {
                        opacity: 1;
                    }
                }
            }
            .customers-logo span {
                text-decoration: none;
                display: block;
                height: 120px;
                line-height: 120px;
                background-color: $white_text;
                border: 1px solid rgb(235,237,237);
                /* &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: #000;
                    opacity: 0.3;
                }
                &:hover {
                    &:after {
                        display: none;
                    }
                } */
            }
            .customers-logo img {
                max-width: 100px;
                height: auto;
                vertical-align: middle;
                
            }
            @media (max-width: $lg - 1px) {
                .customers-logo {
                    width: 100px;
                }
                .customers-logo span {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                }
                .customers-logo img {
                    width: 80px;
                }
            }
            @media (max-width: $md - 1px) {
                .customers-logo img {
                    width: 85px;
                }
            }
            @media (max-width: $sm - 1px) {
                width: 100%;
                .customers-logo {
                    width: 130px;
                    margin-bottom: 20px;
                    margin-right: 10px;

                    &:nth-child(even) {
                        margin: 0 0 20px 10px;
                    }
                }
                .customers-logo span {
                    width: 130px;
                    height: 130px;
                    line-height: 130px;
                }
                .customers-logo img {
                    width: 105px;
                }
            }

            @media (min-width: 440px) and (max-width: $md) {
                .customers-logo {
                    margin: 0 10px 10px 0;

                    &:nth-child(even) {
                        margin: 0 10px 10px 0;
                    }
                }
            }
        }
        .minimazed {
            height: 120px;
            @media (min-width: $md) {
                height: auto;
            }
            @media (max-width: $md - 1px) {
                height: 110px;
            }
            @media (max-width: $sm - 1px) {
                height: 140px;
            }
        }
    }
}
.portfolio-wide-container {
    padding: 0;
    width: 1350px;
    .portfolio-item {
        display: block;
        height: 460px;
        float: left;
        background-color: $gray_fon_info_item;
        @media (max-width: $md - 1px) {
            height: 300px;
        }

        @media (min-width: $sm) and (max-width: $md - 1px) {
            width: 50% !important;
            &:last-child {
                width: 100% !important;
                .wrap-inner {
                    img {
                        width: 50%;
                    }
                }
            }
        }
    }
    .image-item {
        overflow: hidden;
        position: relative;
        &:after {
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #000;
            opacity: 0.2;
        }
        &:hover {
            &:after {
                opacity: 0;
            }
        }

        a {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 10;
            &:first-child {
                display: block !important;
            }
        }
        .wrap-inner {
            position: absolute;
            width: 2000px;
            height: 2000px;
            margin: -1000px 0 0 -1000px;
            left: 50%;
            top: 50%;
            text-align: center;
            line-height: 2000px;
        }
        .image-hint {
            display: none;
            width: 100%;
            height: 190px;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 25px 20px 20px 20px;
            background-color: rgba(#252525,0.8);
            p {
                margin-bottom: 0px;
                &:first-child {
                    margin-bottom: 7px;
                }
                &:nth-child(2) {
                    margin-bottom: 20px;
                }
            }
            .hint-title, .hint-number {
                font-size: 1.57em;
            }
            .hint-title, {
                color: $white_text;
            }
            .hint-number {
                margin-top: 10px;
            }
            @media (max-width: $lg - 1px) {
                width: 330px;
                height: 160px;
                padding: 18px 12px 20px 20px;
                p {
                    font-size: 0.93em;
                    &:first-child {
                        margin-bottom: 7px;
                    }
                    &:nth-child(2) {
                        margin-bottom: 7px;
                    }
                }
                .hint-title, .hint-number {
                    font-size: 1.43em;
                }
            }
            @media (max-width: $md - 1px) {
                width: 100%;
                height: 150px;
                .hint-title, .hint-number {
                    font-size: 1.28em;
                }
                p {
                    &:first-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(2) {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
    .wide-image {
        width: 980px;
        .wrap-inner img {
            width: 980px;
        }
    }
    .medium-image {
        width: 685px;
        .wrap-inner img {
            height: 460px;
        }
    }
    .full-width {
        .wrap-inner img {
            width: 100%;
            max-width:980px;
            height: auto;
            @media (max-width: $md - 1px) {
                width: auto;
            }
        }
    }
    .small-image {
        width: 370px;
    }
    .full-height {
        .wrap-inner img {
            height: 460px;
            width: auto;
            @media (max-width: $lg - 1px) {
                width: auto;
            }
        }
    }
    .info-item {
        vertical-align: middle;
        width: 295px;
        .info-sub-item {
            display: inline-block;
            position: relative;
            width: 100%;
            height: 50%;
            color: $white_text;
            .items-text-wrapper {
                padding-top: 40px;
                padding-left: 30px;
                padding-right: 50px;
                p {
                    margin: 0 0 4px;
                }
            }
            .items-text-wrapper, .item-title, .item-number, {
                display: inline-block;
                position: relative;
            }
            .item-title {
                font-size: 2.14em;
                line-height: 1.3;
            }
            .item-number {
                font-size: 5.71em;
                line-height: 1.1;
                padding-right: 10px;
            }
            @media (max-width: $lg - 1px) {
                .items-text-wrapper {
                    padding-top: 50px;
                    padding-left: 20px;
                    padding-right: 24px;
                }
                .item-title {
                    font-size: 1.57em;
                }
                .item-number {
                    font-size: 3.14em;
                    padding-right: 5px;
                }
            }
            @media (max-width: $md - 1px) {
                .items-text-wrapper {
                    padding-top: 85px;
                    padding-right: 20px;

                }


            }
            @media (max-width: $sm - 1px) {
                .items-text-wrapper {
                    padding-top: 60px;
                }
            }
        }
        .dark-fon {
            .item-number {
                color: $line_active_color;
            }
        }
        .light-fon {
            background-color: $line_active_color;
            .item-number {
                color: $main_text;
            }
            .item-text {
                color: $white_text;
            }
            @media (max-width: $lg - 1px) {
                .items-text-wrapper {
                    padding-top: 18%;
                }
            }
        }
    }
}
.item-text {
    line-height: 1.5;
    display: inline-block;
    position: relative;
    color: $button_close_color;
}
.portfolio-wide-container {
    @media (max-width: $lg - 1px) {
        width: 1024px;
        .wide-image {
            width: 694px;
            .wrap-inner img {
                height: 540px;
                width:auto;
            }
        }
        .medium-image {
            width: 530px;
        }
        .small-image {
            width: 330px;
        }
        .info-item {
            width: 164px;
        }
    }
}
.portfolio-wide-container {
    @media (max-width: $md - 1px) {
        .wide-image, .medium-image, .small-image, .info-item {
            width: 100%;
        }
        .wide-image {
            .wrap-inner img {
                width:auto;
                height: 300px;
            }
        }
        .medium-image {
            .wrap-inner img {
                height: auto;
                max-width: 100%;
            }
        }
        .small-image {
            .wrap-inner img {
                height: auto;
                width: 100%;
            }
        }
        .info-item {
            .info-sub-item {
                display: block;
                float: left;
                width: 50%;
                height: 100%;
            }
        }
    }
}
.portfolio-wide-container {
    @media (max-width: $sm - 1px) {
        width: 100%;
        .info-item {
            height: 250px;
        }
    }
}
.image-item {
    @media (max-width: $md - 1px) and (min-width: $sm) {
        &:last-child {
            width: 640px;
            .wrap-inner img {
                width: 640px;
                height: auto;
            }
        }
    }
}

.contacts-wrapper {
    // display: none;
    margin-left: -15px;
    margin-right: -15px;
    // padding-bottom: 60px;
    min-height: 618px;
    background-color: $main_text;
    //border-bottom: 2px solid $gray_fon;
    @media (max-width: $md - 1px) {
        top:62px;
    }
    @media (max-width: $sm - 1px) {
        margin-left: 0;
        margin-right: 0;
        .contacts-container {
            width: 320px;
        }
    }
}
.contacts-row {
    color: $white_text;
    margin-left: -15px;
    margin-right: -15px;
    @media (max-width: $sm - 1px) {
        margin-left: 0;
        margin-right: 0;
    }
    .contacts-col {
        padding-left: 0;
        padding-right: 0;
        height: 100%;
        @media (max-width: $md - 1px) {
            .border-left {
                border-left: 0;
            }
        }
        @media (max-width: $sm - 1px) {
            .border-left {
                border-left: 0;
                padding-left: 0;
            }
        }
        .contacts-block {
            margin-top: 50px;
            /* margin-left: 40px; */
            @media (max-width: $sm - 1px) {
                margin-left: 0;
            }
            .contacts-title {
                font-size: 2.14em;
                line-height: 0.8;
            }
            .contact-item {
                @media (max-width: $sm - 1px) {
                    position: relative;
                }
            }
            .menu-phone-block {
                font-size: 2.14em;
                @media (max-width: $md - 1px) {
                    font-size: 2.57em;
                }
            }
            .callback-text, .short-adres {
                @media (max-width: $md - 1px) {
                    font-size: 1.14em;
                }
            }
            .menu-email {
                font-size: 1.57em;
            }

            @media (min-width: $md) {
                max-width: 50%;
                margin-left: 0;
            }
        }
        .contacts-second-block {
            margin-top: -1px;
            .contact-item:first-child {
                padding-top: 27px;
            }
            .social-icons {
                padding-bottom: 20px;
            }
        }
        .map-wrapper {
            position: absolute;
            top: -335px;
            right: 0;
            /* width: 820px; */
            left: 50%;
            height: 618px;
            background-color: $white_text;

            @media (max-width: $lg - 1px) {
                top: -310px;
                left: 50%;
            }

            @media (max-width: $md - 1px) {
                position: static;
                width: auto;
                height: 300px;
                margin: 27px 12px 0
            }
            @media (max-width: $sm - 1px) {
                width: 100%;
                margin-left: 0;
                height: 300px !important;
            }
            .map-content {
                width: 100%;
                height: 100%;
            }

        }
    }
}
// 404
.not-found-wrapper {
    background-color: $main_text;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    .not-found {
        padding-top: 60px;
        padding-left: 30px;
        margin-bottom: -5px;
        .number-wrapper {
            display: inline-block;
            position: relative;
            height: 565px;
            width: 634px;
            background: url(../images/0-1.png) 17px 110px no-repeat;
            &:before {
                content: " ";
                display: block;
                position: absolute;
                top: 132px;
                left: 26px;
                height: 431px;
                width: 139px;
                background: url(../images/44-9.png) 0px 0px no-repeat;
            }
            .number-page {
                display: block;
                font-size: 12.86em;
                line-height: 0.72;
                color: $white_text;
            }
            .contour {
                display: inline-block;
                position: relative;
                margin-left: 270px;
                height: 431px;
                width: 367px;
                background: url(../images/0-2.png) 0px 4px no-repeat;
                &:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    top: 36px;
                    left: 222px;
                    height: 400px;
                    width: 76px;
                    background: url(../images/44-8.png) 0px 0px no-repeat;
                }
                @media (max-width: $md - 1px) {
                    margin-left: 240px;
                }
            }
        }
        .messages-block {
            display: inline-block;
            height: 565px;
            width: 610px;
            padding-top: 50px;
            background: url(../images/city.png) -1112px 225px no-repeat;
            background-size: auto 60%;
            overflow: hidden;
            @media (max-width: $md - 1px) {
                height: 0;
                padding-top: 0;
            }
            .messages-wrapper {
                .message-page {
                    display: block;
                    padding-left: 18px;
                    padding-bottom: 20px;
                    font-size: 1.57em;
                    line-height: 1;
                    color: $white_text;
                }
            }
            .link-start-page {
                display: block;
                color: $gray_message;
                font-size: 1.43em;
                line-height: 1;
                padding: 0 0 10px 10px;
                margin-left: 6px;
                border-bottom: 2px solid $gray_fon;
                border-left: 2px solid $gray_fon;
                width: 150px;
                &:hover {
                    color: $white_text;
                    border-color: $white_text;
                }
                &:active {
                    color: $white_text;
                    border-color: $line_active_color;
                }
            }
        }
    }
    @media (max-width: $lg - 1px) {
        .not-found {
            padding-left: 15px;
            .messages-block {
                width: 330px;
            }
        }
    }
    @media (max-width: $md - 1px) {
        margin-left: 0;
        margin-right: 0;
        .not-found {
        margin-bottom: -25px;
            .messages-block {
                .messages-wrapper {
                    position: absolute;
                    top: 100px;
                    left: 350px;
                }
            }
        }
    }
    @media (max-width: $sm - 1px) {
        .not-found {
            padding-top: 30px;
            padding-left: 10px;
            .number-wrapper {
                height: 540px;
                width: 255px;
                background-position: 0 202px;
                &:before {
                    top: 202px;
                    background-size: 78%;
                }
                .number-page {
                    font-size: 6.86em;
                }
                .contour {
                    display: none;
                }
            }
            .messages-block {
                .messages-wrapper {
                    position: absolute;
                    padding-top: 30px;
                    padding-left: 12px;
                    border-left: 2px solid #E8771A;
                    top: 99px;
                    left: 25px;
                    .message-page {
                        padding-left: 0px;
                        font-size: 1.28em;
                    }
                    .link-start-page{
                        font-size: 1.28em;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
.scan-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    .scan {
        margin-bottom: -5px;
        height: 240px;
        background: url(../images/city.png) 0px 33px no-repeat $main_text;
        background-size: auto 85%;
        .scan-messages-wrapper {
            color: $button_close_color;
            display: inline-block;
            position: relative;
            width: 450px;
            margin-top: 50px;
            margin-left: 30px;
            @media (max-width: $sm - 1px) {
                margin-top: 30px;
                margin-left: 10px;
                height: 210px;
            }
            .title-page {
                display: block;
                color: $white_text;
                font-size: 2.14em;
                line-height: 0.72;
                @media (max-width: $sm - 1px) {
                    font-size: 1.57em;
                }
            }
            .number-result, .inquiry-result, .text-inquiry {
                display: inline-block;
                font-size: 1.57em;
                line-height: 0.72;
                @media (max-width: $sm - 1px) {
                    font-size: 1.14em;
                }
            }
            .number-result {
                padding-top: 30px;
                padding-right: 5px;
            }
            .inquiry-result {
                padding-right: 5px;
            }
            .request {
                padding-top: 15px;
                input {
                    font-family: 'Open Sans', sans-serif;
                }
                @media (max-width: $sm - 1px) {
                    position: static;
                    width: 50%;
                }
            }
        }
    }
    .result-wrapper {
        display: inline-block;
        width: 690px;
        padding-top: 10px;
        overflow: hidden;
        padding-bottom: 30px;
        @media (max-width: $md - 1px) {
            width: 95%;
        }
        @media (max-width: $md - 1px) {
            padding-top: 30px;
        }
        @media (max-width: $sm - 1px) {
            padding-top: 15px;
        }
        .messages-block {
            display: block;
            padding-left: 18px;
            font-size: 1.71em;
            line-height: 1;
            @media (max-width: $sm - 1px) {
                padding-bottom: 20px;
            }
            li {
                color: $line_active_color;
                padding-left: 6px;
                margin-bottom: 10px;
                list-style: decimal;
            }
            .result-shell {
                display: block;
                margin-top: 50px;
                .result-item {
                    line-height: 1;
                    padding: 0 0 4px 6px;
                    margin-left: 6px;
                    border-bottom: 2px solid $button_close_color;
                    border-left: 2px solid $button_close_color;
                    &:hover {
                        border-color: $line_active_color;
                    }
                }
            }
            @media (max-width: $lg - 1px) {
                font-size: 1.57em;
            }
            @media (max-width: $sm - 1px) {
                font-size: 1.14em;
                padding-left: 0;
                .result-shell {
                    margin-top: 30px;
                }
            }
        }
    }
}
